import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { StylesNotFound } from './styles';
import { AlertForm } from '../../Utils/Alert';

export const NotFound = () => {
  return (
    <StylesNotFound>
      <div className="main">
        <h2>404</h2>
        <div className="text">
          <h3>Opss! Pagina no existe.</h3>
        </div>

        <h3>
          Ir al menu principal
          <span style={{ marginLeft: '1rem' }}>
            <Link to={'/'}>Aqui</Link>
          </span>
        </h3>
      </div>
    </StylesNotFound>
  );
};
