import React, { useEffect, useState } from 'react';

import { StylesClients } from './styles';
import { AlertConfirm, AlertForm, AlertLoading, ICON } from '../../Utils/Alert';
import { Button } from '../../Components/Buttons';
import { Input } from '../../Components/Input/InputSecondary';
import { TableClients } from '../../Components/Table/clients';
import { useModal } from '../../Hooks/useModal';
import { Modal } from '../../Components/Modals';
import {
  useCreateClientMutation,
  useDeleteClientMutation,
  useGetAllClientsQuery,
  useSearchClientQuery,
  useUpdateClientMutation,
} from '../../Store/api/clients';
import { InputForm } from '../../Components/Input';
import { Form } from '../../Components/Form';
import { EmailValidate } from '../../Utils/ValidateFields';
import Lupa from '../../Assets/img/Actions/Lupa.svg';

export const Clients = () => {
  const [isOpenModal1, openModal1, closeModal1] = useModal(false);
  const [isCreateForm, setIsCreateForm] = useState(true);
  const [searchClient, setSearchClient] = useState('');
  const [idClient, setIdClient] = useState(null);
  const [dataForm, setDataForm] = useState({
    firstname: '',
    lastname: '',
    company: '',
    email: '',
    phone: '',
  });
  const [errorDataForm, setErrorDataForm] = useState({
    firstname: '',
    lastname: '',
    company: '',
    email: '',
  });
  const [dataClient, setDataClient] = useState([]);

  let { data: getAllClients, isLoading, refetch: fetchAllClients } = useGetAllClientsQuery();
  let { data: getSearchClients, isLoading: isLoadingSearch } = useSearchClientQuery(searchClient);
  let [createClientFetch, { isLoading: isLoadingCreate, isSuccess: isSuccessCreate }] = useCreateClientMutation();
  let [updateClientFetch, { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate }] = useUpdateClientMutation();
  let [deleteClientFetch, { isLoading: isLoadingDelete, isSuccess: isSuccessDelete }] = useDeleteClientMutation();

  useEffect(() => {
    if (getSearchClients && searchClient) {
      setDataClient(getSearchClients.data);
    } else if (getAllClients) {
      setDataClient(getAllClients.data);
    }
  }, [getAllClients, getSearchClients, searchClient]);

  useEffect(() => {
    if (isSuccessCreate) {
      AlertForm({
        title: 'Cliente creado correctamente',
        icon: ICON.SUCCESS,
      });
      closeModal1();
      fetchAllClients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessCreate]);

  useEffect(() => {
    if (isSuccessUpdate) {
      AlertForm({
        title: 'Cliente Actualizado correctamente',
        icon: ICON.SUCCESS,
      });
      closeModal1();
      fetchAllClients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUpdate]);

  useEffect(() => {
    if (isSuccessDelete) {
      AlertForm({
        title: 'Cliente Eliminado Correctamente.',
        icon: ICON.SUCCESS,
      });
      closeModal1();
      fetchAllClients();
    } else if (isLoadingDelete) {
      AlertLoading({ title: 'Eliminando...' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessDelete, isLoadingDelete]);

  const changeDataClient = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
  };

  const handleCreateUpdateClient = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let validations = {
      email: EmailValidate(dataForm.email),
      firstName: dataForm.firstname.length >= 3,
      lastName: dataForm.lastname.length >= 3,
      company: dataForm.company.length >= 3,
    };

    setErrorDataForm({
      email: !validations.email ? 'Correo con formato invalido' : '',
      company: !validations.company ? 'Empresa debe tener 3 caracteres' : '',
      firstname: !validations.firstName ? 'Nombre debe tener 3 caracteres' : '',
      lastname: !validations.lastName ? 'Apellido debe tener 3 caracteres' : '',
    });
    if (Object.values(validations).every((el) => el === true)) {
      if (isCreateForm) {
        createClientFetch(dataForm);
      } else {
        updateClientFetch({ id: idClient, body: dataForm });
      }
    }
  };

  const openCreateClient = () => {
    openModal1();
    setIsCreateForm(true);
  };
  const openEditClient = (client) => {
    openModal1();
    setIsCreateForm(false);
    setDataForm({
      firstname: client.firstname,
      lastname: client.lastname,
      company: client.company,
      email: client.email,
      phone: client.phone,
    });
    setIdClient(client.id);
  };
  const deleteClient = async ({ id, firstname, lastname }) => {
    let result = await AlertConfirm({
      title: 'Seguro de Eliminar Cliente?',
      text: `Cliente: ${firstname} ${lastname}, sera eliminado!`,
      icon: ICON.WARNING,
    });
    if (result.isConfirmed) {
      deleteClientFetch(id);
    }
  };
  const inputSearchClients = (e) => {
    if (e.target.value) {
      setSearchClient(e.target.value);
    } else {
      setSearchClient('');
      fetchAllClients();
    }
  };
  return (
    <StylesClients>
      <h2 className="title-main">Clientes</h2>

      <section className="action-workflow">
        <div className="section-search">
          <Input icon={Lupa} type="search" placeholder={'Buscar Cliente...'} onChange={inputSearchClients} />
        </div>
        <div>
          <Button onPress={openCreateClient}>Crear Cliente</Button>
        </div>
      </section>
      <section>
        <TableClients
          dataClients={dataClient || []}
          isLoading={isLoading}
          openEditClient={openEditClient}
          deleteClient={deleteClient}
        />
      </section>
      {/* modal */}

      <Modal title={`${isCreateForm ? 'Nuevo' : 'Editar'} Cliente`} isOpen={isOpenModal1} closeModal={closeModal1}>
        <h2>Formulario</h2>
        <div className="form-container">
          <Form onSubmit={handleCreateUpdateClient}>
            <div className="row">
              <div className="form-control col-6">
                <label htmlFor="">Nombre *</label>
                <InputForm
                  placeholder={'Nombre'}
                  type="text"
                  name={'firstname'}
                  onChange={changeDataClient}
                  value={dataForm.firstname}
                  error={errorDataForm.firstname ? true : false}
                />
                <p className={`msg-error ${errorDataForm.firstname ? 'active' : ''}`}>{errorDataForm.firstname}</p>
              </div>
              <div className="form-control col-6">
                <label htmlFor="">Apellido *</label>
                <InputForm
                  placeholder={'Apellido'}
                  type="text"
                  name={'lastname'}
                  onChange={changeDataClient}
                  value={dataForm.lastname}
                  error={errorDataForm.lastname ? true : false}
                />
                <p className={`msg-error ${errorDataForm.lastname ? 'active' : ''}`}>{errorDataForm.lastname}</p>
              </div>
              <div className="form-control col-6">
                <label htmlFor="">Empresa *</label>
                <InputForm
                  placeholder={'Empresa'}
                  type="text"
                  name={'company'}
                  onChange={changeDataClient}
                  value={dataForm.company}
                  error={errorDataForm.company ? true : false}
                />
                <p className={`msg-error ${errorDataForm.company ? 'active' : ''}`}>{errorDataForm.company}</p>
              </div>
              <div className="form-control col-6">
                <label htmlFor="">Email *</label>
                <InputForm
                  placeholder={'Email'}
                  type="text"
                  name={'email'}
                  onChange={changeDataClient}
                  value={dataForm.email}
                  error={errorDataForm.email ? true : false}
                />
                <p className={`msg-error ${errorDataForm.email ? 'active' : ''}`}>{errorDataForm.email}</p>
              </div>
              <div className="form-control col-6">
                <label htmlFor="">Telefono</label>
                <InputForm
                  placeholder={'Telefono'}
                  type="text"
                  name={'phone'}
                  onChange={changeDataClient}
                  value={dataForm.phone}
                />
              </div>
            </div>

            <div className="text-center">
              <Button type="submit" disabled={isLoadingCreate || isLoadingUpdate}>
                {isLoadingCreate || isLoadingUpdate ? 'Enviando...' : isCreateForm ? 'Crear' : 'Editar'}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </StylesClients>
  );
};
