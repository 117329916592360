import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const templatesApi = createApi({
  reducerPath: 'templatesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + '/templates',
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem('user');
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllTemplates: builder.query({
      query: () => '/workflows',
    }),
    getTemplatesFilters: builder.mutation({
      query(body) {
        return {
          url: `/workflows/filters`,
          method: 'POST',
          body,
        };
      },
    }),
    createTemplate: builder.mutation({
      query(body) {
        return {
          url: `/create`,
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const {
  useGetAllTemplatesQuery,
  useGetTemplatesFiltersMutation,
  useCreateTemplateMutation,
} = templatesApi;
