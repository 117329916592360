import React, { useEffect, useState } from 'react';
import { TemplateStyles } from './styles';
import New from '../../Assets/img/Actions/Nuevo.svg';
import GoogleSheet from '../../Assets/img/Logo/Google Sheets.png';
import { Input } from '../../Components/Input/InputSecondary';

import { InputForm } from '../../Components/Input';
import { useGetAllClientsQuery, useSearchClientQuery } from '../../Store/api/clients';
import { Button } from '../../Components/Buttons';
import { useCreateWorkflowMutation } from '../../Store/api/workflows';
import { AlertConfirm, AlertLoading, ICON } from '../../Utils/Alert';
import { useSelector } from 'react-redux';
import Lupa from '../../Assets/img/Actions/Lupa.svg';
import { LinkButton } from '../../Components/Links';
import { BackLink } from '../../Components/Links/backLink';

export const TemplateCreateWorkflow = ({ filterWorkflowFetch, closeModal, isOpenModal }) => {
  const [view, setView] = useState('main');
  const [clientSelected, setClientSelected] = useState(null);
  const [dataForm, setDataForm] = useState({ name: '' });
  const [searchClient, setSearchClient] = useState('');
  const [dataClient, setDataClient] = useState([]);
  const { idUser } = useSelector((state) => state.login);
  let { data: getAllClients, isLoading, refetch: fetchAllClients } = useGetAllClientsQuery();
  let { data: getSearchClients, isLoading: isLoadingSearch } = useSearchClientQuery(searchClient);
  let [createWorkflowFetch, { isLoading: isLoadingCreate, isSuccess: isSuccessCreate, data: dataCreate }] =
    useCreateWorkflowMutation();

  useEffect(() => {
    const execute = async () => {
      let urlN8N = `${process.env.REACT_APP_N8N_API_URL}/workflow/${dataCreate.data.id}`;

      let result = await AlertConfirm({
        title: 'Se creo workflow, exitosamente!',
        text: `Desea modificar el workflow ?`,
        icon: ICON.SUCCESS,
      });
      filterWorkflowFetch({ term: '' });
      closeModal();
      if (result.isConfirmed) {
        const win = window.open(urlN8N, '_blank');
        win.focus();
      }
    };
    if (isSuccessCreate) {
      execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCreate, isSuccessCreate]);

  useEffect(() => {
    if (getSearchClients && searchClient) {
      setDataClient(getSearchClients.data);
    } else if (getAllClients) {
      setDataClient(getAllClients.data);
    }
  }, [getAllClients, getSearchClients, searchClient]);

  useEffect(() => {
    if (isLoadingCreate) {
      AlertLoading({ title: 'Creando WorkFlow...' });
    }
  }, [isLoadingCreate]);

  useEffect(() => {
    if (!isOpenModal) {
      setSearchClient('');
      setDataForm({ name: '' });
      setClientSelected(null);
      setView('main');
    }
  }, [isOpenModal]);

  const selectWorkflow = (idWorkflow) => {
    //TODO Peticion detalle de la seleccion del workflow

    setView('selectWF');
    fetchAllClients();
  };

  const backListWorkflow = () => {
    setView('main');
  };

  const handleClient = (client) => {
    setClientSelected(client);
  };
  const handleFormWF = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
  };
  const handleCreateWorkflow = async () => {
    try {
      createWorkflowFetch({
        workflow: {
          nodes: [],
          connections: {},
          settings: {
            saveExecutionProgress: true,
            saveManualExecutions: true,
            saveDataErrorExecution: 'all',
            saveDataSuccessExecution: 'all',
            executionTimeout: 3600,
            timezone: 'America/Bogota',
          },
          staticData: null,
        },
        name: dataForm.name,
        idClient: clientSelected.id,
        author: idUser,
      });
    } catch (err) {
      console.warn(err);
    }
  };

  const inputSearchClients = (e) => {
    if (e.target.value) {
      setSearchClient(e.target.value);
      setClientSelected(null);
    } else {
      setSearchClient('');
      fetchAllClients();
    }
  };
  return (
    <TemplateStyles>
      {view === 'main' && (
        <>
          <section className="actions-search">
            <Input icon={Lupa} type="search" placeholder={'Buscar Plantilla...'} />
          </section>
          <section className="templates-workflows row">
            <div className="col-3">
              <ul className="list-menu">
                <li className="active">Todas las plantillas</li>
                <li>Categoria 1</li>
                <li>Categoria 2</li>
                <li>Categoria 3</li>
              </ul>
            </div>
            <div className="col-9">
              <div className="items-workflow">
                <div className="item-wf">
                  <div className="header-wf">
                    <div className="title">Categoria 1</div>
                    <LinkButton fontSize={'0.8rem'} text={'Ver mas'} />
                  </div>
                  <div className="card-wf">
                    <div className="card">
                      <div className="img-section">
                        <img src={New} alt="Nuevo" />
                        <div className="abs-hover">
                          <Button onPress={() => selectWorkflow('En blanco')}>Seleccionar</Button>
                          <LinkButton fontSize={'1rem'} text={'Ver mas'} />
                        </div>
                      </div>
                      <div>En blanco</div>
                    </div>
                    <div className="card">
                      <div className="img-section">
                        <img src={GoogleSheet} alt="Nuevo" />
                        <div className="abs-hover">
                          <Button>Seleccionar</Button>
                          <LinkButton fontSize={'1rem'} text={'Ver mas'} />
                        </div>
                      </div>
                      <div>GoogleSheets x Sistema</div>
                    </div>{' '}
                    <div className="card">
                      <div className="img-section">
                        <img src={GoogleSheet} alt="Nuevo" />
                        <div className="abs-hover">
                          <Button>Seleccionar</Button>
                          <LinkButton fontSize={'1rem'} text={'Ver mas'} />
                        </div>
                      </div>
                      <div>Contactos de Hubspot</div>
                    </div>
                  </div>
                </div>
                <div className="item-wf">
                  <div className="header-wf">
                    <div className="title">Categoria 2</div>
                    <LinkButton fontSize={'0.8rem'} text={'Ver mas'} />
                  </div>
                  <div className="card-wf">
                    <div className="card">
                      <img src={GoogleSheet} alt="Nuevo" />
                      <div>GoogleSheets x Sistema</div>
                    </div>
                    <div className="card">
                      <img src={GoogleSheet} alt="Nuevo" />
                      <div>GoogleSheets x Sistema</div>
                    </div>{' '}
                    <div className="card">
                      <img src={GoogleSheet} alt="Nuevo" />
                      <div>Contactos de Hubspot</div>
                    </div>
                  </div>
                </div>
                <div className="item-wf">
                  <div className="header-wf">
                    <div className="title">Categoria 3</div>
                    <LinkButton fontSize={'0.8rem'} text={'Ver mas'} />
                  </div>
                  <div className="card-wf">
                    <div className="card">
                      <div>
                        <img src={GoogleSheet} alt="Nuevo" />
                      </div>
                      <div>GoogleSheets x Sistema</div>
                    </div>
                    <div className="card">
                      <img src={GoogleSheet} alt="Nuevo" />
                      <div>GoogleSheets x Sistema</div>
                    </div>{' '}
                    <div className="card">
                      <img src={GoogleSheet} alt="Nuevo" />
                      <div>Contactos de Hubspot</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      {view === 'selectWF' && (
        <>
          <section className="title-modal">
            <BackLink onPress={backListWorkflow} />
            <p>En blanco</p>
          </section>
          <section className="templates-workflows row">
            <div className="col-3">
              <div className="resume-column">
                <div className="img-resume">
                  <img src={GoogleSheet} alt="" />
                </div>
                <div>
                  <p>
                    Descripción de la plantilla."Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                  </p>
                </div>
              </div>
            </div>
            <div className="col-9">
              <div className="contact-workflow">
                <h3>Datos Workflow</h3>
                <div>
                  <div>
                    <label>Nombre Workflow</label>
                    <InputForm
                      type="text"
                      placeholder={'Escriba el nombre del Workflow'}
                      onChange={handleFormWF}
                      name="name"
                      value={dataForm.name}
                    />
                  </div>
                </div>
                <h3>Asigna un cliente al nuevo Workflow</h3>
                <div className="section-clients">
                  <div>
                    <label>Busqueda Cliente</label>
                    <InputForm
                      type="text"
                      placeholder={'Escriba tu correo o nombre'}
                      onChange={inputSearchClients}
                      value={searchClient}
                    />
                  </div>
                  <div>
                    <h4>Resultados: {dataClient.length} Clientes Encontrados </h4>
                    <div className="grid-contacts">
                      {dataClient?.map((client) => {
                        return (
                          <div
                            className={`card-contact ${client.id === clientSelected?.id ? 'active' : ''} `}
                            key={client.id}
                          >
                            <div className="info-contact">
                              <p>
                                <b>
                                  {client.firstname} {client.lastname}
                                </b>
                              </p>
                              <p>{client.company}</p>
                            </div>

                            <Button onPress={() => handleClient(client)}>
                              {clientSelected?.id === client.id ? 'Seleccionado' : 'Seleccionar'}
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="actions-workflow">
                    <Button onPress={handleCreateWorkflow} disabled={!(clientSelected && dataForm.name)}>
                      Crear
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </TemplateStyles>
  );
};
