import { ContainerInput, StyleInputSecondary } from './styles';

export const Input = ({ disabled, type, bgColor, color, fontSize, placeholder, onChange, value, name, icon: Icon }) => (
  <ContainerInput>
    <StyleInputSecondary
      disabled={disabled}
      type={type}
      bgColor={bgColor}
      color={color}
      fontSize={fontSize}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      name={name}
      icon={Icon ? true : false}
    />
    <div className={Icon ? 'icon' : ''}>
      <img src={Icon} alt="" />
    </div>
  </ContainerInput>
);
