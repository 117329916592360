import { configureStore } from '@reduxjs/toolkit';
import { appsApi } from './api/apps';
import { clientsApi } from './api/clients';
import { countApi } from './api/count';
import { executionsApi } from './api/executions';
import { templatesApi } from './api/templates';
import { workflowsApi } from './api/workflows';

import { loginSlice } from './slices/login';
export const store = configureStore({
  reducer: {
    login: loginSlice.reducer,
    [clientsApi.reducerPath]: clientsApi.reducer,
    [appsApi.reducerPath]: appsApi.reducer,
    [workflowsApi.reducerPath]: workflowsApi.reducer,
    [executionsApi.reducerPath]: executionsApi.reducer,
    [templatesApi.reducerPath]: templatesApi.reducer,
    [countApi.reducerPath]: countApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
            .concat(clientsApi.middleware)
            .concat(workflowsApi.middleware)
            .concat(executionsApi.middleware)
            .concat(templatesApi.middleware)
            .concat(countApi.middleware)
            .concat(appsApi.middleware)
            ;
  },
});
