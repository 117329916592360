import React, { useEffect, useState } from 'react'
import { Button } from '../Buttons'
import { DropdownMenuList } from '../Dropdown/menuList'
import { InputForm } from '../Input'
import { StyleTable } from '../Table/styles'
import Dots from '../../Assets/img/Actions/Dots.svg';
import { Form } from '../Form'
import { useSelector } from 'react-redux'
import { useUpdateCoreWorkflowMutation } from '../../Store/api/workflows'
import { AlertConfirm, AlertForm, AlertLoading, ICON } from '../../Utils/Alert'

export const FormDescription = ({workflowCore, closeModal, filterWorkflowFetch}) => {
  const { idUser } = useSelector((state) => state.login);
  let [updateWorkflowCoreFetch,
    { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, isError: isErrorUpdate, error: errorUpdate },
  ] = useUpdateCoreWorkflowMutation();
  const [dataForm, setDataForm] = useState({name: '', description: '', date: '', id: '' });
  const [dataTable, setDataTable] = useState(workflowCore || []);
  const [errorDataForm, setErrorDataForm] = useState({});
  const [isNewDescription, setIsNewDescription] = useState(true);

  useEffect(() => {
    if(!workflowCore) return;
    setDataTable(workflowCore.versionDescription)
  }, [workflowCore])


  useEffect(() => {
    if (isSuccessUpdate) {
      AlertForm({ title: 'Actualizado correctamente...', icon: ICON.SUCCESS });
      filterWorkflowFetch({term: ""})
      closeModal();
    } else if (isErrorUpdate) {
      AlertForm({
        title: 'Hubo un inconveniente al actualizar',
        text: errorUpdate.data?.message || '',
        icon: ICON.WARNING,
      });
    } else if (isLoadingUpdate) {
      AlertLoading({ title: 'Actualizando...' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUpdate, isLoadingUpdate, isErrorUpdate]);


  const handleUpsertDescription = (e) => {
    e.preventDefault();
    e.stopPropagation();

    //TODO Update Template

    updateWorkflowCoreFetch({
      id: workflowCore.id,
      body: {
        versionDescription: dataTable
      },
    });
  }

  const changeData = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
  };

  const handleAddVersion = () => {
    let validateDate = dataForm.date.length >= 1; //Regex para fechas
    let validateName = dataForm.name.length >= 3;
    let validateDescription = dataForm.description.length >= 10;

    setErrorDataForm({
      date: !validateDate ? 'No ha seleccionado Fecha' : '',
      name: !validateName ? 'Nombre Versión debe tener 3 caracteres' : '',
      description: !validateDescription ? 'Descripción debe tener 10 caracteres' : '',
    });
    if (validateName && validateDescription && validateDate) {
      let id = window.crypto.randomUUID();
      let addDescription = structuredClone(dataTable);
      addDescription.push({...dataForm, id});
      setDataTable(addDescription);
      setDataForm({date: '', name: '', description: '', id: ''});
    }
  }

  const handleDeleteDescription = async(description) => {
    let result = await AlertConfirm({ title: 'Esta seguro de Eliminar Descripción?', text: description.name });
    if (result.isConfirmed) {
      let deleteDescription= dataTable.filter(el => el.id !== description.id);
      setDataTable(deleteDescription);
    }
  }
  const handleEditDescription = (version) => {
    setIsNewDescription(false);
    setDataForm(version);
  }

  const handleUpdateDescription = () => {
    setIsNewDescription(true);
    let clone = structuredClone(dataTable);
    let updateDescription = clone.find(el => el.id === dataForm.id);
    updateDescription.name = dataForm.name;
    updateDescription.date = dataForm.date;
    updateDescription.description = dataForm.description;
    setDataTable(clone);
    setDataForm({name: '', description: '', date: '', id: '' });
  }
  const handleCancelDescription = () => {
    setIsNewDescription(true);
    setDataForm({name: '', description: '', date: '', id: '' });
  }
  return (
    <Form onSubmit={handleUpsertDescription}>
      <h2>Gestion </h2>
      <div className="row">
        <div className="form-control col-6">
          <label htmlFor="">Nombre Version *</label>
          <InputForm
            placeholder={'Nombre'}
            type="text"
            name={'name'}
            onChange={changeData}
            value={dataForm.name}
            error={errorDataForm.name ? true : false}
          />
          <p className={`msg-error ${errorDataForm.name ? 'active' : ''}`}>{errorDataForm.name}</p>
        </div>
        <div className="form-control col-6">
          <label htmlFor="">Fecha Version *</label>
          <InputForm
            placeholder={'Nombre'}
            type="date"
            name={'date'}
            onChange={changeData}
            value={dataForm.date}
            error={errorDataForm.date ? true : false}
          />
          <p className={`msg-error ${errorDataForm.date ? 'active' : ''}`}>{errorDataForm.date}</p>
        </div>
        <div className="form-control col-6">
          <label htmlFor="">Descripción *</label>
          <InputForm
            placeholder={'Descripción'}
            type="text"
            name={'description'}
            onChange={changeData}
            value={dataForm.description}
            error={errorDataForm.description ? true : false}
          />
          <p className={`msg-error ${errorDataForm.description ? 'active' : ''}`}>{errorDataForm.description}</p>
        </div>
      </div>
      <div className='section-btn'>
        {isNewDescription && <Button bgColor={"var(--green-color)"} bgColorHover={"var(--green-color)"} onPress={handleAddVersion}>Agregar</Button>}
        {!isNewDescription && 
        <>
          <Button bgColor={"var(--green-color)"} bgColorHover={"var(--green-color)"} onPress={handleUpdateDescription}>Actualizar</Button>
          <Button onPress={handleCancelDescription}>Cancelar</Button>
        </>}
       
      </div>
      <StyleTable cols="1fr 1fr 1.2fr 120px">
        <div className="header-table">
          <div>Nombre Versión</div>
          <div>Fecha Versión</div>
          <div>Descripción</div>
          <div></div>
        </div>
        <div className="body-table">
        {dataTable.map((version, idx) => {
        return (
          <div className="row-table" key={`version-${idx}`}>
            <div className="col-table">{version.name}</div>
            <div className="col-table">{version.date}</div>
            <div className="col-table">{version.description}</div>
            <div className="col-table">
              <DropdownMenuList img={Dots}>
                <div className="item" onClick={() => handleEditDescription(version)}>
                  <img src={Dots} alt="edit" />
                  <p>Editar</p>
                </div>
                <div className="item" onClick={() => handleDeleteDescription(version)}>
                  <img src={Dots} alt="newAppoitment" />
                  <p>Eliminar</p>
                </div>
              </DropdownMenuList>
            </div>
          </div>
        )})}
        </div>
      </StyleTable>
      <div >
        <Button type="submit">Guardar</Button>
      </div>
    </Form>
  )
}
