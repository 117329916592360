import styled from 'styled-components';

export const TemplateStyles = styled.div`
  & .actions-search {
    padding: 0 3rem 0rem 0;
    border-bottom: 1px solid var(--lightgray-color);
    & > section {
      margin-left: auto;
      width: fit-content;
    }
  }
  & .title-modal {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    border-bottom: 1px solid var(--lightgray-color);
    font-weight: bold;
    font-size: 22px;
  }
  & .templates-workflows {
    & .list-menu {
      list-style: none;
      padding: 0.4rem;
      margin: 0;

      & li {
        padding: 1rem 2rem;
        margin-bottom: 0.5rem;
        border-radius: 1rem;
        &.active {
          background-color: var(--primary-color);
          color: var(--white-color);
        }
        &:hover {
          background-color: var(--lightgray-color);
          cursor: pointer;
        }
      }
    }
    & .items-workflow {
      background-color: var(--page-background);
      height: 100%;
      padding: 1rem;
      & .item-wf {
        padding-bottom: 1rem;
        & .header-wf {
          padding-bottom: 0.5rem;
          display: flex;
          justify-content: space-between;
          & .title {
            font-weight: bold;
          }
          & .action {
            font-size: 12px;
            color: var(--primary-color);
            &:hover {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
        & .card-wf {
          display: flex;
          gap: 1.5rem;
          & .card {
            background-color: var(--white-color);
            padding: 1rem;
            border: 1px solid var(--lightgray-color);
            border-radius: 8px;
            width: 33%;
            height: 130px;
            text-align: center;
            position: relative;
            & .img-section {
              height: 86px;
            }
            & img {
              width: auto;
              height: 70px;
            }
            & .abs-hover {
              display: none;
            }
            &:hover {
              outline: 2px solid var(--primary-color);
              box-shadow: 0px 6px 9px 3px #00000026;
              cursor: pointer;
              & .abs-hover {
                position: absolute;
                top: 0;
                left: 0;
                background-color: #ffffffbf;
                backdrop-filter: blur(2px);
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                border-radius: 8px;
                padding: 1rem;
                text-align: center;
                gap: 0.4rem;
              }
            }
          }
        }
      }
    }

    & .resume-column {
      padding: 1rem;
      & > .img-resume {
        margin-bottom: 1.2rem;
      }
    }

    & .contact-workflow {
      padding: 1rem 3rem;

      & > h3 {
        text-align: center;
      }
      & .section-clients {
        position: relative;
      }
      & .grid-contacts {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
        justify-content: center;
        gap: 1rem;
        margin-bottom: 1.2rem;
        & button {
          width: fit-content;
          margin: auto;
        }
      }
      & .actions-workflow {
        text-align: center;
        position: sticky;
        bottom: 0;
        left: 0;
        background-color: white;
        padding: 1rem;
      }
    }
    & .card-contact {
      border: 1px solid var(--lightgray-color);
      padding: 1rem;
      border-radius: 8px;
      margin: 0.2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      & .info-contact {
        margin-bottom: 1.2rem;
      }
      &.active {
        border: 2px solid var(--primary-color);
        box-shadow: var(--dropshadow);
      }
    }
  }
`;
