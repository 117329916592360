import styled from 'styled-components';

export const StyleForm = styled.form`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 50px;
  text-align: center;
  width: 100%;
  & .row {
    width: 100%;
  }
  & .forgot-password {
    margin: 1rem 0 2rem;
    color: var(--primary-color);
    font-weight: bold;
    text-align: right;
    font-size: 14px;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  & .form-control {
    text-align: left;
    padding: 0.3rem;
  }
  p.msg-error {
    font-size: 12px;
    color: red;
    text-align: left;
    transition: height 0.5s linear;
    height: 0;
  }
  p.msg-error.active {
    height: 16px;
  }

  a {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
  }

  & .container-select {
    margin: 8px 0;
  }

  & .section-btn {
    display: flex;
    gap: 1rem;
  }
`;
