import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { StylesHome } from './styles';
import { AlertClose, AlertForm } from '../../Utils/Alert';

export const Home = () => {
  let navigate = useNavigate();
  const { nameUser } = useSelector((state) => state.login);
  useEffect(() => {
    AlertClose();
  }, []);

  return (
    <StylesHome>
      <h2>Home Page Integrador</h2>
      <h3>Bienvenido {nameUser}</h3>
    </StylesHome>
  );
};
