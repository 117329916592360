import styled from 'styled-components';

export const StyleBadge = styled.button`
  background-color: ${(props) => props.bgColor || 'var(--primary-color)'};
  color: ${(props) => props.color || 'white'};
  border: 1px solid ${(props) => props.borderColor || 'transparent'};
  border-radius: 4px;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  padding: 8px 12px;
  letter-spacing: 1px;
  text-align: center;
  font-size: ${(props) => props.fontSize || 'var(--fs-small)'};
  font-weight: bold;
  outline: none;
  position: relative;
  display: inline-block;
  width: ${(props) => (props.widthFull ? '100%' : null)};
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  & .close {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: var(--red-color);
    padding: 3px 5px;
    border-radius: 1rem;
    z-index: 10;
    &:hover {
      cursor: pointer;
      color: black;
    }
  }
`;
