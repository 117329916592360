import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { Button } from '../../../../../Components/Buttons';
import { LinkStyles, StateStyle } from '../../../../../Components/Table/HubSpot/styles';
import { TableHubSpot } from '../../../../../Components/Table/HubSpot/tableHubSpot';
import { capitalLetter } from '../../../../../Utils/String';
import { formatDate } from '../../../../../Utils/times';
import { dataMapping } from '../../Mapping/dataDummy';
import { CheckboxStyles } from './styles';
import Dots from '../../../../../Assets/img/Actions/Dots.svg';
import { DropdownMenuList } from '../../../../../Components/Dropdown/menuList';


export const MappingTable = ({review = false}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState(dataMapping);
  let navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        id: 'status',
        accessorFn: row => row.active ? "true": "false",
        cell: ({ getValue }) => {
          return <div style={{marginLeft: "1rem"}}>
              {getValue() === "true" ? 
              <svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" viewBox="0 0 24 24"><path fill="#7fd1de" fillRule="evenodd" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10m0-1.2a8.8 8.8 0 1 0 0-17.6a8.8 8.8 0 0 0 0 17.6m-1.172-6.242l5.809-5.808l.848.849l-5.95 5.95a1 1 0 0 1-1.414 0L7 12.426l.849-.849l2.98 2.98z"/></svg>
              : 
              <svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" viewBox="0 0 32 32"><path fill="lightgray" d="M6.837 23.749A11.95 11.95 0 0 1 4 16C4 9.373 9.373 4 16 4c2.954 0 5.658 1.067 7.749 2.837zm1.414 1.414L25.163 8.251A11.95 11.95 0 0 1 28 16c0 6.627-5.373 12-12 12a11.95 11.95 0 0 1-7.749-2.837M2 16c0 7.732 6.268 14 14 14s14-6.268 14-14S23.732 2 16 2S2 8.268 2 16"/></svg>}      
            </div>
        },
        options: [{id: "true", label: "Active"}, {id: "false", label: "Inactive"}],
        meta: {
          filterVariant: 'select',
        },
        size: 70
      },
      {
        accessorFn: row => row.app_property?.label,
        id: 'app_property',
        header: () => <span>Shopify</span>,
        cell: info => info.getValue(),
      },
      {
        id: 'direction',
        accessorKey: 'direction',
        header: () => 'Direction',
        cell({ getValue }) {
            return <div style={{marginLeft: "1rem"}}>
                {getValue() === "app_hs" ?
                <svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" viewBox="0 0 24 24"><path fill="lightgray" d="M16.15 13H5q-.425 0-.712-.288T4 12t.288-.712T5 11h11.15L13.3 8.15q-.3-.3-.288-.7t.288-.7q.3-.3.713-.312t.712.287L19.3 11.3q.15.15.213.325t.062.375t-.062.375t-.213.325l-4.575 4.575q-.3.3-.712.288t-.713-.313q-.275-.3-.288-.7t.288-.7z"/></svg>
                :
                getValue() === "hs_app"  ?
                <svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" viewBox="0 0 24 24"><path fill="lightgray" d="m7.85 13l2.85 2.85q.3.3.288.7t-.288.7q-.3.3-.712.313t-.713-.288L4.7 12.7q-.3-.3-.3-.7t.3-.7l4.575-4.575q.3-.3.713-.287t.712.312q.275.3.288.7t-.288.7L7.85 11H19q.425 0 .713.288T20 12t-.288.713T19 13z"/></svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" viewBox="0 0 24 24"><path fill="lightgray" d="m5.825 17l1.9 1.9q.3.3.288.7t-.313.7q-.3.275-.7.288t-.7-.288l-3.6-3.6q-.15-.15-.213-.325T2.426 16t.063-.375t.212-.325l3.6-3.6q.275-.275.688-.275t.712.275q.3.3.3.713t-.3.712L5.825 15H20q.425 0 .713.288T21 16t-.288.713T20 17zm12.35-8H4q-.425 0-.712-.288T3 8t.288-.712T4 7h14.175l-1.9-1.9q-.3-.3-.287-.7t.312-.7q.3-.275.7-.288t.7.288l3.6 3.6q.15.15.213.325t.062.375t-.062.375t-.213.325l-3.6 3.6q-.275.275-.687.275T16.3 12.3q-.3-.3-.3-.712t.3-.713z"/></svg>
            } 
              </div>
          },
        options: [{id: "app_hs", label: "Shopify to HubSpot"}, {id: "hs_app", label: "HubSpot to Shopify"}, {id: "bidirecctional", label: "Bidireccional"}],
        meta: {
          filterVariant: 'select',
        },
      },
      {
        id: 'hs_property',
        accessorFn: row => row.hs_property?.label,
        header: () => <span>HubSpot</span>,
        cell: info => info.getValue(),
      },
      {
        id: 'type',
        accessorKey: 'type',
        header: () => <span>Mapping Type</span>,
        cell: info => capitalLetter(info.getValue()),
        options: [{id: "default", label: "Default"}, {id: "custom", label: "Custom"}],
        meta: {
          filterVariant: 'select',
        },
      },
      {
        id: 'active',
        accessorKey: 'active',
        header: () => <span></span>,
        cell({ row, getValue }) {
            return <div style={{display: "flex", gap: "1rem", alignItems: "center"}}>
                
                <Button className={"hubspot button--sm"}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <span><svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 20 20"><path fill="currentColor" d="m2.292 13.36l4.523 4.756L.5 20zM12.705 2.412l4.522 4.755L7.266 17.64l-4.523-4.754zM16.142.348l2.976 3.129c.807.848.086 1.613.086 1.613l-1.521 1.6l-4.524-4.757L14.68.334l.02-.019c.119-.112.776-.668 1.443.033"/></svg></span>
                    </div>
                </Button>
                <Button className={"hubspot button--sm"}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <span><svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 16 16"><path fill="currentColor" d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/></svg></span>
                    </div>
                </Button>
                <CheckboxStyles>
                    <label class="switch">
                        <input type="checkbox" checked={getValue()}/>
                        <span class="slider"></span>
                    </label>

              </CheckboxStyles>
            </div>
            
            
          },
        meta: {
            filterVariant: 'hidden',
          },
      },
    ],
    []
  )
  return (
   <>
    <section>
      {!review && <div className='section-btn'>
        <Button className={"hubspot button--sm"} onPress={() => setShowFilter(!showFilter)}>{showFilter ? "Hidden" : "Show"} filters</Button>
      </div>}
      <div>
        <TableHubSpot dataTable={data} showFilter={showFilter} columns={review ? columns.slice(0, -2) :columns} rowExpand={false} />
      </div>
    </section>
   </>
  );
};
