import styled from 'styled-components';

export const DropdownContainer = styled.div`
  position: relative;
  & > button {
    border: ${(props) => (props.border ? '2px solid ' + props.border : 'none')} !important;
    color: ${(props) => (props.color ? props.color : 'black')} !important;
    padding: 0.3rem;
    margin: 0;
    background-color: ${(props) => props.bgButtonDropdown} !important;
    & img {
      width: 12px;
      &.is-open {
        transform: rotate(180deg);
      }
    }
  }

  & section {
    display: none;
    position: absolute;
    top: 36px;
    left: 10px;
    background: ${(props) => (props.bgColor ? props.bgColor : 'white')};
    padding: 0.6rem;
    z-index: 998;
    box-shadow: 0px 0px 20px 3px rgb(0 0 0 / 25%);
    border-radius: 0.8rem;
    &.is-open {
      display: flex;
      flex-direction: column;
    }
    & p {
      margin: 0;
      margin-bottom: 0.4rem;
    }

    & a,
    & button {
      padding: 0.6rem;
      text-decoration: none;
      &:hover {
        background-color: var(--red-color);
      }
    }
    & a:visited {
      color: black;
    }

    & img {
      width: 16px;
      margin-right: 0.6rem;
    }
  }
`;

export const DropdownContainerMenu = styled(DropdownContainer)`
  position: ${(props) => (props.notification ? 'static' : 'relative')};
  & .actions {
    width: 32px;
    &:hover {
      cursor: pointer;
      background-color: var(--lightgray-color);
      border-radius: 1rem;
      box-shadow: 0px 4px 4px 0px #00000078;
    }
  }
  & section {
    top: ${(props) => (props.location === 'top' ? 'auto' : '42px')};
    left: 0;
    right: auto;
    bottom: ${(props) => (props.location === 'top' ? '50px' : 'auto')};
    min-width: 150px;
    max-width: 300px;
    width: max-content;
    & .item {
      padding: 0.5rem;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        background-color: var(--lightgray-color);
      }
      & p {
        font-size: 1rem;
        margin: 0;
        text-transform: none;
      }
    }
  }
`;
