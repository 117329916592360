export const EmailValidate = (email) => {
  const result =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(
      email.toLowerCase()
    );

  return result;
};

export const validateOnlyNumber = (data) => {
  const result = /^[0-9]+$/.test(data);
  return result;
};

export const validateCharacterSpace = (data) => {
  const result = /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]+$/.test(data);
  return result;
};

export const validateOnlyNumberCharacters = (data) => {
  const result = /^[a-zA-Z\d]+$/.test(data);
  return result;
};
export const validateOnlyNumberCharactersGuionMedioBajo = (data) => {
  const result = /^[a-zA-Z\d\-_]+$/.test(data);
  return result;
};

export const hasNumber = (data) => {
  const result = /\d/.test(data);
  return result;
};
export const hasLowerCase = (data) => {
  const result = /[a-z]/.test(data);
  return result;
};
export const hasUpperCase = (data) => {
  const result = /[A-Z]/.test(data);
  return result;
};
export const hasSpecialCharacter = (data) => {
  const result = /[\W_]/.test(data);
  return result;
};

// solo se valida que tenga 8 caractes de longitud, falta definir en diseño los items de las politicas
export const validatePassword = (password, passwordCompare = null, t) => {
  let validatePass = {
    text: '',
    level: 3,
    lenghtPass: true,
    CapitalLetter: true,
    NumberPass: true,
    SpecialChar: true,
  };
  let count = 3;
  if (passwordCompare !== null && password !== passwordCompare) {
    validatePass.text = t('login.passDontMatch');
    count--;
  }
  // Minimo 8 caracteres
  if (password.length < 8) {
    validatePass.text = t('login.passwordMin');
    validatePass.lenghtPass = false;
    count -= 3;
  }
  //tiene un numero
  if (!hasNumber(password)) {
    validatePass.NumberPass = false;
    count--;
  }
  //tiene mayusculas
  if (!hasUpperCase(password)) {
    validatePass.CapitalLetter = false;
    count--;
  }
  //tiene caracteres especiales
  if (!hasSpecialCharacter(password)) {
    validatePass.SpecialChar = false;
    count--;
  }
  validatePass.level = count;

  return validatePass;
};

export const validateName = ({ name, lastname }, t) => {
  let validateName = {
    isValid: true,
    text: '',
  };
  // Minimo 3 caracteres
  if (name.length < 3 && lastname.length < 3) {
    validateName.isValid = false;
    validateName.text = t('login.nameLastnameLength');
  } else if (name.length < 3) {
    validateName.isValid = false;
    validateName.text = t('login.lastnameLength');
  } else if (lastname.length < 3) {
    validateName.isValid = false;
    validateName.text = t('login.nameLength');
  }

  return validateName;
};
