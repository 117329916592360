import styled, { keyframes } from 'styled-components';
import { device } from '../../Constants/device';

export const StylesClients = styled.section`
  & .action-workflow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .section-search {
    display: flex;
    align-items: center;
    & > button {
      margin-left: 1rem;
      padding: 1rem;
      min-width: auto;
    }
  }
`;
