import { useState, useEffect } from 'react';
import { AlertForm, ICON } from '../../Utils/Alert';
import { ApiRequest } from '../../Utils/ApiRequest';

export const useRegisterUser = () => {
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(null);
  const [dataRegister, setDataRegister] = useState(null);

  useEffect(() => {
    if (!dataRegister) return;
    const registerUser = async () => {
      try {
        setIsLoading(true);
        setResponse(null);
        setError(null);

        const resRegister = await ApiRequest.post('/users/signup', dataRegister);

        setResponse(resRegister);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    registerUser();
  }, [dataRegister]);

  useEffect(() => {
    if (response) {
      AlertForm({ title: response.message, text: 'Por favor, loguearse para ingresar!', icon: ICON.SUCCESS });
    }
    if (error) AlertForm({ title: error.statusText, icon: ICON.ERROR });
  }, [response, error]);

  return { isLoading, setDataRegister };
};
