import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EmailValidate } from '../../Utils/ValidateFields';
import { authenticationUser } from '../../Store/slices/login/thunks';
import { StylesLogin } from './styles';
import { Button } from '../../Components/Buttons';
import { InputForm } from '../../Components/Input';
import { AlertClose, AlertForm, AlertLoading } from '../../Utils/Alert';
import Logo from '../../Assets/img/Logo/logo.svg';
import { useRegisterUser } from '../../Hooks/Register/useRegisterUser';
import { Form } from '../../Components/Form';

export const Login = () => {
  const { isLoadingLogin, failed } = useSelector((state) => state.login);
  const { isLoading: isLoadingRegister, setDataRegister } = useRegisterUser();
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(true);
  const [dataFormLogin, setDataFormLogin] = useState({
    email: '',
    password: '',
  });
  const [dataFormRegister, setDataFormRegister] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
  });
  const [errorDataFormLogin, setErrorDataFormLogin] = useState({
    email: '',
    password: '',
  });
  const [errorDataFormRegister, setErrorDataFormRegister] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
  });

  useEffect(() => {
    if (isLoadingLogin) {
      AlertLoading({ title: 'Validando información' });
    } else if (failed) {
      AlertForm({ title: failed, text: 'Por favor validar nuevamente' });
    } else {
      AlertClose();
    }
  }, [isLoadingLogin, failed]);

  useEffect(() => {
    if (isLoadingRegister) {
      AlertLoading({ title: 'Guardando información...' });
    }
  }, [isLoadingRegister]);

  const changeDataLogin = (e) => {
    setDataFormLogin({ ...dataFormLogin, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let isValidEmail = EmailValidate(dataFormLogin.email);
    // Minimo 8 caracteres
    let validatePass = dataFormLogin.password.length >= 8;

    setErrorDataFormLogin({
      email: !isValidEmail ? 'Correo con formato invalido' : '',
      password: !validatePass ? 'Contraseña debe tener 8 caracteres' : '',
    });
    if (isValidEmail && validatePass) {
      dispatch(
        authenticationUser({
          email: dataFormLogin.email.toLowerCase(),
          password: dataFormLogin.password,
        })
      );
    }
  };
  const changeDataRegister = (e) => {
    setDataFormRegister({ ...dataFormRegister, [e.target.name]: e.target.value });
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    let isValidEmail = EmailValidate(dataFormRegister.email);
    // Minimo 8 caracteres
    let validatePass = dataFormRegister.password.length >= 8;
    let validateFirstName = dataFormRegister.firstname.length >= 3;
    let validateLastName = dataFormRegister.lastname.length >= 3;

    setErrorDataFormRegister({
      email: !isValidEmail ? 'Correo con formato invalido' : '',
      password: !validatePass ? 'Contraseña debe tener 8 caracteres' : '',
      firstname: !validateFirstName ? 'Nombre debe tener 3 caracteres' : '',
      lastname: !validateLastName ? 'Apellido debe tener 3 caracteres' : '',
    });
    if (isValidEmail && validatePass && validateFirstName && validateLastName) {
      setDataRegister(dataFormRegister);
    }
  };

  return (
    <StylesLogin>
      <div className={`container ${isLogin ? '' : 'right-panel-active'}`}>
        <div className="form-container sign-up-container">
          <div className="title-logo">
            <img src={Logo} alt="Logo" />
            <h3>Integrador</h3>
          </div>
          <Form onSubmit={handleRegister}>
            <h2>Crear una cuenta.</h2>
            {/*  <div className="social-container">
              <a href="#" className="social">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#" className="social">
                <i className="fab fa-google-plus-g"></i>
              </a>
              <a href="#" className="social">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
            <span>or use your email for registration</span> */}
            <div>
              <InputForm
                type="text"
                placeholder="Nombre"
                onChange={changeDataRegister}
                value={dataFormRegister.firstname}
                name="firstname"
                error={errorDataFormRegister.firstname ? true : false}
              />
              <p className={`msg-error ${errorDataFormRegister.firstname ? 'active' : ''}`}>
                {errorDataFormRegister.firstname}
              </p>
            </div>
            <div>
              <InputForm
                type="text"
                placeholder="Apellido"
                onChange={changeDataRegister}
                value={dataFormRegister.lastname}
                name="lastname"
                error={errorDataFormRegister.lastname ? true : false}
              />
              <p className={`msg-error ${errorDataFormRegister.lastname ? 'active' : ''}`}>
                {errorDataFormRegister.lastname}
              </p>
            </div>

            <div>
              <InputForm
                type="email"
                placeholder="Email"
                onChange={changeDataRegister}
                value={dataFormRegister.email}
                name="email"
                error={errorDataFormRegister.email ? true : false}
              />
              <p className={`msg-error ${errorDataFormRegister.email ? 'active' : ''}`}>
                {errorDataFormRegister.email}
              </p>
            </div>
            <div>
              <InputForm
                type="password"
                placeholder="Password"
                onChange={changeDataRegister}
                value={dataFormRegister.password}
                name="password"
                error={errorDataFormRegister.password ? true : false}
              />
              <p className={`msg-error ${errorDataFormRegister.password ? 'active' : ''}`}>
                {errorDataFormRegister.password}
              </p>
            </div>

            <Button type="submit">Registrarse</Button>
          </Form>
        </div>
        <div className="form-container sign-in-container">
          <div className="title-logo">
            <img src={Logo} alt="Logo" />
            <h3>Integrador</h3>
          </div>
          <Form onSubmit={handleLogin}>
            <h2>Iniciar Sesión.</h2>
            {/* <div className="social-container">
              <a href="#" className="social">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#" className="social">
                <i className="fab fa-google-plus-g"></i>
              </a>
              <a href="#" className="social">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
            <span>or use your account</span> */}
            <div>
              <InputForm
                type="email"
                placeholder="Email"
                onChange={changeDataLogin}
                value={dataFormLogin.email}
                name="email"
                error={errorDataFormLogin.email ? true : false}
              />
              <p className={`msg-error ${errorDataFormLogin.email ? 'active' : ''}`}>{errorDataFormLogin.email}</p>
            </div>
            <div>
              <InputForm
                type="password"
                placeholder="Password"
                onChange={changeDataLogin}
                value={dataFormLogin.password}
                name="password"
                error={errorDataFormLogin.password ? true : false}
              />
              <p className="msg-error">{errorDataFormLogin.password}</p>
            </div>

            <span className="forgot-password">Olvidaste tu contraseña?</span>
            <Button type="submit">Ingresar</Button>
          </Form>
        </div>
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-left">
              <h2>Bienvenido!</h2>
              <p className="text">Para conectarse con nosotros inicie sesión con tu información personal.</p>
              <Button className="ghost" onPress={() => setIsLogin(true)}>
                Iniciar Sesión
              </Button>
            </div>
            <div className="overlay-panel overlay-right">
              <h2>Hola!</h2>
              <p className="text">Ingresa aqui, con tus datos personales para crear una cuenta.</p>
              <Button className="ghost" onPress={() => setIsLogin(false)}>
                Registrarse
              </Button>
            </div>
          </div>
        </div>
      </div>
    </StylesLogin>
  );
};
