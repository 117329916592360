import React, { useEffect, useMemo, useState } from 'react';
import { StyleHubspot } from '../Mapping/styles';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { StylesProgress } from './styles';
import { TableHubSpot } from '../../../../Components/Table/HubSpot/tableHubSpot';

import { dataTransactions } from '../Mapping/dataDummy';
import { Button } from '../../../../Components/Buttons';
import { useValidationTokenQuery } from '../../../../Store/api/apps';
import { useNavigate } from 'react-router-dom';


export const Transactions = () => {
  const [percentage, setPercentage] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [transactionsData, setTransactionsData] = useState({
    total: 10000,
    current: 9244,
    cutDay: 10,
  });
  const [data, setData] = useState(dataTransactions);
  let { data: dataValidation, error: errorValidation, isLoading } = useValidationTokenQuery();
  let navigate = useNavigate();
  useEffect(() => {
    setPercentage(Math.floor((transactionsData.current / transactionsData.total) * 100));
  }, [transactionsData])
  

  useEffect(() => {
    if(errorValidation) {
      if(errorValidation.status === 403) {
        navigate('/');
      }
    }
  }, [errorValidation])
   
  const columns = useMemo(
    () => [

      {
        accessorFn: row => row.category,
        id: 'category',
        header: () => <span>Category</span>,
        cell: info => info.getValue().replaceAll("_", " ").toUpperCase(),
      },
      {
        id: 'count',
        accessorFn: row => Number(row.count),
        header: () => <span>Quantity</span>,
        cell: info => info.getValue().toLocaleString("en"),
        meta: {
          filterVariant: 'range',
        },
      },

    ],
    []
  )

  const handleOpenTab = () => {
    window.open(window.location.href, '_blank').focus();
    
    setTimeout(() => {
      window.parent.postMessage(JSON.stringify({"action": "DONE"}), "*");
    }, 1000);
  }
  return (
    <StyleHubspot>
      <section className='section-general'>
        <Tabs>
          <TabList>
            <Tab className={"tab-item"}>Transactions View</Tab>
          </TabList>
          <TabPanel>
            <h2>Transacciones</h2>
            <StylesProgress>
              <div className='circle-transactions'>
                <SemiCircleProgressBar percentage={percentage } stroke={percentage < 60 ? "var(--primary-hubspot-color)": percentage < 90 ? "#f4d739": "#e33737"} strokeWidth={15} diameter={250} showPercentValue />
              </div>
              <div className='info-transactions'>
                <h2> {transactionsData.current.toLocaleString("en")} / {transactionsData.total.toLocaleString("en")} </h2>
                <div>
                  <p>
                    <b>Fecha de Corte: </b>Dia {transactionsData.cutDay} de cada mes
                  </p>
                </div>
              </div>
            </StylesProgress>
            <section style={{maxWidth: "600px"}}>
              <div className='section-btn'>
                <Button className={"hubspot button--sm"} onPress={() => setShowFilter(!showFilter)}>{showFilter ? "Hidden" : "Show"} filters</Button>
                <Button className={"hubspot button--sm"}>Sync now</Button>
                <Button className={"hubspot button--sm"} onPress={handleOpenTab}>New tab</Button>
              </div>
              <div >
                <TableHubSpot dataTable={data} showFilter={showFilter} columns={columns} rowExpand={false} />
              </div>
            </section>
          </TabPanel>
        </Tabs>
      </section>
    </StyleHubspot>
  );
};
