import styled from 'styled-components';

export const StyleButton = styled.button`
  background-color: ${(props) => props.bgColor || 'var(--primary-color)'};
  color: ${(props) => props.color || 'white'};
  border: 1px solid ${(props) => props.borderColor || 'transparent'};
  border-radius: 16px;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  cursor: pointer;
  padding: 12px 24px;
  letter-spacing: 1px;
  text-align: center;
  font-size: ${(props) => props.fontSize || 'var(--fs-small)'};
  font-weight: bold;
  outline: none;
  position: relative;
  display: inline-block;
  min-width: ${(props) => (props.minWidth || '150px')};
  width: ${(props) => (props.widthFull ? '100%' : null)};
  transition: background-color 0.5s linear, color 0.5s linear, border 0.5s linear, transform 0.5s linear,
    border 0.5s linear;

  &:hover {
    transform: scale(1.03);
    background-color: ${(props) => props.bgColorHover || 'var(--red-color)'};
  }
  &:active {
    transform: scale(0.95);
  }
  &:disabled {
    cursor: not-allowed;

    background-color: var(--disabled-color);
  }
  &:focus {
    outline: none;
  }
  &.ghost {
    background-color: transparent;
    border-color: #ffffff;
  }
  &.hubspot {
    font-family: "Lexend Deca", Helvetica, Arial, sans-serif;
    font-size: 12px;
    background-color: ${(props) => props.bgColor || '#eaf0f6'};
    color: ${(props) => props.color || '#506e91'};
    border: 1px solid ${(props) => props.borderColor || '#cbd6e2'};
    border-radius: 4px;
    font-weight: normal;
    border-radius: 3px;
    text-align: center;
    user-select: none;
    transition: all .15s ease-out;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    min-width: auto;
    &.button--sm {
      padding: 8px 16px;
    }
    &:hover {
      background-color: ${(props) => props.bgColorHover || '#f5f8fa'};
      color: #506e91;
    }

    &.btn-secondary {
      background-color: transparent;
      border-color: #fff;
      color: #fff;
      font-size: 14px;
      line-height: 16px;
      padding: 11px 24px;
      font-family: var(--uicomponents-font-family, "Lexend Deca", Helvetica, Arial, sans-serif);
      font-weight: 500;
      &.light {
        border-color: #dfe3eb;
        color: #7c98b6;
      }
    }
    &.btn-active {
      background-color: #ff7a59;
      border-color: #ff7a59;
      color: #fff;
      font-size: 14px;
      line-height: 16px;
      padding: 11px 24px;
      font-family: var(--uicomponents-font-family, "Lexend Deca", Helvetica, Arial, sans-serif);
      font-weight: 500;
    }
  }
`;
