import React, { useEffect, useState } from 'react';

import { StylesTemplates } from './styles';
import { useGetTemplatesFiltersMutation } from '../../Store/api/templates';
import { Button } from '../../Components/Buttons';
import { Input } from '../../Components/Input/InputSecondary';
import { Table } from '../../Components/Table/templates';
import { useModal } from '../../Hooks/useModal';
import { Modal } from '../../Components/Modals';
import Filter from '../../Assets/img/Actions/Filter.svg';
import Lupa from '../../Assets/img/Actions/Lupa.svg';
import { DropdownMenuList } from '../../Components/Dropdown/menuList';
import Select from 'react-select';
import { styleTheme } from '../../Styles/SelectTheme';
import { TemplateManagement } from '../../Template/AdministrarPlantilla';

export const Templates = () => {
  const [isOpenModalCreate, openModalCreate, closeModalCreate] = useModal(false);
  const [dataTemplate, setDataTemplate] = useState([]);
  const [searchWorkflow, setSearchWorkflow] = useState('');

  let [filterTemplateFetch, { isLoading: isLoadingFilter, isSuccess: isSuccessFilter, data: getDataFilter }] =
    useGetTemplatesFiltersMutation();

  useEffect(() => {
    filterTemplateFetch({ term: '' });
  }, [filterTemplateFetch]);

  useEffect(() => {
    if (isSuccessFilter) {
      setDataTemplate(getDataFilter.data);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessFilter]);

  const inputSearchTemplate = (e) => {
    let obj = {};
    if (e.target.value) {
      obj.term = e.target.value;
      filterTemplateFetch(obj);
      setSearchWorkflow(e.target.value);
    } else {
      obj.term = '';
      setSearchWorkflow('');
      filterTemplateFetch(obj);
    }
  };
  const handleSearchByFilter = () => {
    let obj = {};
 
    if (searchWorkflow) obj.term = searchWorkflow;
    filterTemplateFetch(obj);
  };

  return (
    <StylesTemplates>
      <h2 className="title-main">Plantillas</h2>

      <section className="action-workflow">
        <div className="section-search">
          <div>
            <Input icon={Lupa} type="search" placeholder={'Buscar Plantillas...'} onChange={inputSearchTemplate} />
          </div>

          <DropdownMenuList img={Filter} type="button" allowInternalClick={true}>
            <div className="internal-filters">
              <h5>Por usuario</h5>
              <div>
                <Select
                  options={[]}
                  isClearable={true}
                  isMulti
                  isSearchable={true}
                  placeholder="Buscar por usuario"
                  theme={(theme) => styleTheme(theme)}
                />
              </div>
              <div className="filter-action">
                <Button onPress={handleSearchByFilter}>Buscar</Button>
              </div>
            </div>
          </DropdownMenuList>
        </div>
        <div>
          <Button onPress={() => openModalCreate()}>Crear Plantilla</Button>
        </div>
      </section>
      <section>
        <Table
          dataWorkflow={dataTemplate || []}
          isLoading={isLoadingFilter}
          filterWorkflowFetch={filterTemplateFetch}
        />
      </section>
      {/* modal */}

      <Modal
        title="Nueva Plantilla"
        isOpen={isOpenModalCreate}
        closeModal={closeModalCreate}
        size="l"
        closeConfirm={true}
      >
        <TemplateManagement
          filterWorkflowFetch={filterTemplateFetch}
          closeModal={closeModalCreate}
          isOpenModal={isOpenModalCreate}
          isCreate={true}
        />
      </Modal>
    </StylesTemplates>
  );
};
