import { StyleButton } from './styles';

export const Button = ({
  children,
  bgColor,
  color,
  bgColorHover,
  type = 'button',
  onPress,
  disabled,
  fontSize,
  widthFull = false,
  className,
  borderColor,
  minWidth
}) => (
  <StyleButton
    disabled={disabled}
    type={type}
    bgColor={bgColor}
    color={color}
    onClick={onPress}
    fontSize={fontSize}
    bgColorHover={bgColorHover}
    widthFull={widthFull}
    className={className}
    borderColor={borderColor}
    minWidth={minWidth}
  >
    <span>{children}</span>
  </StyleButton>
);
