import styled from 'styled-components';
import { device } from '../../Constants/device';

export const SectionPagination = styled.section`
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
`;

export const StylePagination = styled.section`
  & .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    & .item {
      color: var(--darkgray-color);
      min-width: 24px;
      height: 24px;
      padding: 0.2rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      &.active {
        background-color: var(--primary-color);
        color: white;
      }

      &:hover {
        background-color: var(--darkgray-color);
        color: white;
        cursor: pointer;
      }
    }
  }
`;
