import { StyleBadge } from './styles';

export const Badge = ({
  children,
  bgColor,
  color,
  bgColorHover,
  onPress = () => {},
  onPressClose = () => {},
  disabled,
  fontSize,
  widthFull = false,
  className,
  close,
  borderColor,
}) => (
  <StyleBadge
    disabled={disabled}
    bgColor={bgColor}
    color={color}
    fontSize={fontSize}
    bgColorHover={bgColorHover}
    widthFull={widthFull}
    className={className}
    borderColor={borderColor}
  >
    <div onClick={onPress}>{children}</div>
    {close && <div  onClick={onPressClose} className='close'>x</div>}
  </StyleBadge>
);
