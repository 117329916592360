import styled from 'styled-components';


export const LinkStyles = styled.span`
      background-color: transparent;
      border-color: transparent;
      font-family: "Lexend Deca", Helvetica, Arial, sans-serif;
      font-weight: 600;
      transition: all .15s ease-out;
      color: #0091ae;
      text-decoration: none;
      user-select: inherit;
      border: 0;
      display: inline;
      font-size: inherit;
      line-height: inherit;
      overflow: visible;
      padding: 0;
      text-align: inherit;
      vertical-align: inherit;
      white-space: normal;
      &:hover {
        color: #007a8c;
        text-decoration: underline;
        cursor: pointer;
      }
`;

export const TableStyles = styled.section`
    height: 100%;
    width: 100%;
    overflow: auto hidden;
    position: relative;

    & table {
      font-size: 14px;
      background-color: rgb(255, 255, 255);
      border-collapse: collapse;
      width: 100%;
      border-color: rgb(223, 227, 235);
      border-style: solid;
      border-width: 0px 1px 1px;
      & thead {
        position: sticky;
        top: 0px;
        z-index: 1;
        & tr {
          & th {
            user-select: none;
            font-family: var(--uicomponents-font-family, "Lexend Deca", Helvetica, Arial, sans-serif);
            font-weight: 500;
            font-size: 12px;
            background-color: rgb(245, 248, 250);
            color: rgb(51, 71, 91);
            height: 44px;
            padding: 8px 24px 4px 0px;
            text-align: left;
            text-transform: uppercase;
            vertical-align: middle;
            &:hover {
              background-color: rgb(229, 249, 480);
              cursor: pointer;
              & .btn-drag-order {
                opacity: 1;
              }
            }
            &.active {
              background-color: rgb(234, 240, 246);
              & .btn-drag-order {
                opacity: 1;
              }
            }
            & div {
              display: flex;
              gap: 8px;
              justify-content: space-between;
            }
          }
         
        }
        
      }
      & tbody {
        & tr {
          transition: background-color 150ms ease-out;
          font-weight: 300;
          &:hover {
            background-color: rgb(245, 248, 250);
          }

          & td {
            hyphens: auto;
            overflow-wrap: break-word;
            padding: 10px 24px;
            vertical-align: middle;
          }
        }
      }
      & th, & td {
        border: 1px solid rgb(223, 227, 235);
        border-style: solid;
        border-width: 1px 0px 0px;
        line-height: normal;
        vertical-align: middle;
      }
    }
    & .section-table-header {
      background-color: rgb(245, 248, 250);
      border-width: 1px 1px 0px;
      border-top-style: solid;
      border-right-style: solid;
      border-bottom-style: initial;
      border-left-style: solid;
      border-top-color: rgb(223, 227, 235);
      border-right-color: rgb(223, 227, 235);
      border-bottom-color: initial;
      border-left-color: rgb(223, 227, 235);
      border-image: initial;
      padding: 0.5rem 1.5rem;
      & .input-filter {
        position: relative;
        max-width: 300px;
        & input {
          margin: 0;
        }
        & .icon-search {
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
    }
    & .input-filter input, & .input-filter select  {
      margin-top: 8px;
      padding: 9px 32px 9px 8px;
      border-radius: 3px;
      border: 1px solid #cbd6e2;
      font-size: 14px;
      transition: all .15s ease-out;
      color: #33475b;
      display: block;
      height: 40px;
      line-height: 22px;
      text-align: left;
      vertical-align: middle;
      width: 100%;
      margin-left: 8px;
    }
    & .header-table {

      & .btn-drag-order {
        opacity: 0;
        background-color: transparent;
        border: none;
        &:hover {
          cursor: grab;
        }
      }
    }
    & .expand-section {
      display: flex;
      align-items: center;
      
      gap: 1rem;
      & .btn-expand {
        background-color: transparent;
        display: flex;
        align-items: center;
        padding: 0;
      /*  padding-right: 8px; */
        border: none;
        & span {
          font-family: "Lexend Deca", Helvetica, Arial, sans-serif;
          font-size: 14px;
        }
        &:hover {
          cursor: pointer;

        }
      }
    }
   
   
`;
export const StateStyle = styled.span`
    height: 10px;
    width: 10px;
    
    border-radius: 100%;
    display: inline-block;
    margin-right: 8px;
    aspect-ratio: 1 / 1;
    &.on {
      background-color: rgb(0, 189, 165);
    }
    &.off {
      background-color: rgb(203, 214, 226);
    }
    &.error {
      background-color: rgb(242, 84, 91);
    }
`;
export const PaginationStyles = styled(TableStyles)`
  margin-top: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  & .btn {
    background: transparent;
    border: none;
    padding: 0;
    &.active {
      & svg path {
        fill: var(--primary-hubspot-color);
      }
    }
    &.inactive {
      & svg path {
        fill: #b0c1d4;
      }
    }
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
  & .info-table {
    display: flex;
    align-items: center;
    gap: 1rem;
    & .page-info {
      display: flex;
      gap: 0.5rem;
    }
  }
  & .go-page {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0;
  }
  & .input-filter {
    display: flex;
    gap: 0.5rem;
    & input, & select {
      margin-top: 0;
      border: none;
      color: var(--primary-hubspot-color);
      font-weight: bold;
    }

  }
`;