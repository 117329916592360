import styled, { keyframes } from 'styled-components';
import { device } from '../../Constants/device';

export const StylesWorkflow = styled.section`
  & .action-workflow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .section-search {
    display: flex;
    align-items: center;
    & button {
      margin-left: 1rem;
      padding: 1rem;
      min-width: 60px;
      padding: 0.8rem;
    }
  }
  & .internal-filters {
    width: 320px;
    padding: 0.5rem;
    & h5 {
      margin: 0.5rem;
    }

    & .filter-action {
      text-align: center;
      & > button {
        margin: 1rem 0 0;
      }
    }
  }
`;
