import React from 'react';

import { StyleTable } from './styles';


export const TableList = ({ dataListWorkflow }) => {
  return (
    <>
      <h2 id="executions-workflow">Lista workflow:</h2>
        <div className="form-container" >
            <h3>Cantidad de workflows: {dataListWorkflow?.length  || 0}</h3>
            <div style={{padding: "0 2rem"}}>
              <StyleTable cols="150px 400px 150px">
                <div className="header-table">
                  <div>ID</div>
                  <div>Nombre</div>
                  <div>Estado</div>
                </div>
                <div className="body-table">
                  {dataListWorkflow?.map(workflow => {
                  return (
                    <div className="row-table" key={`execution-${workflow.id}`}>
                      <div className="col-table">{workflow.id}</div>
                      <div className="col-table">{workflow.name}</div>
                      <div className="col-table">
                        <div className="section-status">
                          <div className={`circle ${workflow.active ? 'active' : 'inactive'}`}></div>{' '}
                          {workflow.active ? 'Activo' : 'Inactivo'}
                        </div>
                      </div>
                    </div>
                  )})}
                </div>
              </StyleTable>
            </div>
        </div>

    </>
  );
};
