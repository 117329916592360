import React, { useEffect, useState } from 'react';
import { TemplateStyles } from './styles';
import { InputForm } from '../../Components/Input';

import { Button } from '../../Components/Buttons';
import { AlertConfirm, AlertForm, AlertLoading, ICON } from '../../Utils/Alert';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { styleTheme } from '../../Styles/SelectTheme';
import { Form } from '../../Components/Form';
import { StyleTable } from '../../Components/Table/styles';
import { DropdownMenuList } from '../../Components/Dropdown/menuList';
import Dots from '../../Assets/img/Actions/Dots.svg';
import { useCreateTemplateMutation } from '../../Store/api/templates';
import { useNodeTypes } from '../../Hooks/Resource/useNodeTypes';
import { useCredentialsTypes } from '../../Hooks/Resource/useCredentialsTypes';
import { useUpdateCoreWorkflowMutation, useUpdateWorkflowMutation } from '../../Store/api/workflows';
import { validateOnlyNumberCharactersGuionMedioBajo } from '../../Utils/ValidateFields';

export const TemplateManagement = ({ filterWorkflowFetch, closeModal, isOpenModal, isCreate, dataTemplate }) => {
  const { idUser } = useSelector((state) => state.login);
  
  const [dataForm, setDataForm] = useState(dataTemplate || {name: '', description: '', categories: [], tokens: [], credentials: [] });
  const [errorDataForm, setErrorDataForm] = useState({});

  const [dataFormToken, setDataFormToken] = useState({title: '', name: '', description: "", id: ""});
  const [errorDataFormToken, setErrorDataFormToken] = useState({});
  const [isNewToken, setIsNewToken] = useState(true);

  const [dataFormCredentials, setDataFormCredentials] = useState({node: '', type: '', description: "", id: ""});
  const [errorDataFormCredentials, setErrorDataFormCredentials] = useState({});
  const [isNewCredential, setIsNewCredential] = useState(true);

  let [createTemplateFetch, { isLoading: isLoadingCreate, isSuccess: isSuccessCreate, isError: isErrorCreate, error: errorCreate }] =
  useCreateTemplateMutation();
  let [
    updateTemplateCoreFetch,
    { isLoading: isLoadingCoreUpdate, isSuccess: isSuccessCoreUpdate, isError: isErrorCoreUpdate, error: errorCoreUpdate },
  ] = useUpdateCoreWorkflowMutation();
  let [
    updateTemplateFetch,
    { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, isError: isErrorUpdate, error: errorUpdate },
  ] = useUpdateWorkflowMutation();
  let {isLoadingNodes, responseNodes} = useNodeTypes();
  let { isLoadingCredentials, responseCredentials } = useCredentialsTypes();
  
  useEffect(() => {
    if (isSuccessCreate) {
      AlertForm({ title: 'Plantilla creado correctamente...', icon: ICON.SUCCESS });
      filterWorkflowFetch({ term: '' });
      closeModal();
    } else if (isErrorCreate) {
      AlertForm({
        title: 'Hubo un inconveniente al Crear Plantilla',
        text: errorCreate.data?.message || '',
        icon: ICON.WARNING,
      });
    } else if (isLoadingCreate) {
      AlertLoading({ title: 'Configurando Plantilla...' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessCreate, isLoadingCreate, isErrorCreate]);
  
  useEffect(() => {
    if (isSuccessUpdate) {
      
      updateTemplateCoreFetch({
        id: dataForm.id,
        body: {
          tokens: dataForm.tokens,
          credentials: dataForm.credentials,
          categories: dataForm.categories,
          description: dataForm.description,
        },
      })
    } else if (isErrorUpdate) {
      AlertForm({
        title: 'Hubo un inconveniente al Actualizar Plantilla',
        text: errorUpdate.data?.message || '',
        icon: ICON.WARNING,
      });
    } else if (isLoadingUpdate) {
      AlertLoading({ title: 'Actualizando Plantilla ...' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingUpdate, isSuccessUpdate, isErrorUpdate]);

  useEffect(() => {
    if (isSuccessCoreUpdate) {
      AlertForm({ title: 'Plantilla actualizado correctamente...', icon: ICON.SUCCESS });
      filterWorkflowFetch({ term: '' });
      closeModal();
    } else if (isErrorCoreUpdate) {
      AlertForm({
        title: 'Hubo un inconveniente al Actualizar Plantilla Plantilla',
        text: errorCoreUpdate.data?.message || '',
        icon: ICON.WARNING,
      });
    } else if (isLoadingCoreUpdate) {
      AlertLoading({ title: 'Actualizando Plantilla Base de Datos...' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCoreUpdate, isSuccessCoreUpdate, isErrorCoreUpdate]);

  useEffect(() => {
    if (!isOpenModal && isCreate) {
      setDataForm({ name: '', description: '', categories: [], tokens: [], credentials: [] });
    } else if (!isOpenModal && !isCreate) {
      setDataForm(dataTemplate || { name: '', description: '', categories: [], tokens: [], credentials: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenModal, isCreate]);

  useEffect(() => {
    if(!dataTemplate) return;
    setDataForm(dataTemplate)
  }, [dataTemplate])

  

  const changeDataTemplate = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value });
  };
  const changeDataToken = (e) => {
    setDataFormToken({ ...dataFormToken, [e.target.name]: e.target.value });
  };
  const changeDataCredentials = (e) => {
    setDataFormCredentials({ ...dataFormCredentials, [e.target.name]: e.target.value });
  };

  const handleCreateTemplate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let validateName = dataForm.name.length >= 3;
    let validateDescrition = dataForm.description.length >= 10;

    setErrorDataForm({
      name: !validateName ? 'Nombre debe tener 3 caracteres' : '',
      description: !validateDescrition ? 'Descripción debe tener 10 caracteres' : '',
    });
    if (validateName && validateDescrition) {
      if(isCreate) {
        createTemplateFetch({
          ...dataForm,
          workflow: {
            nodes: [],
            connections: {},
            settings: {
              saveExecutionProgress: true,
              saveManualExecutions: true,
              saveDataErrorExecution: 'all',
              saveDataSuccessExecution: 'all',
              executionTimeout: 3600,
              timezone: 'America/New_York',
            },
            staticData: null,
          },
        
          author: idUser,
        });
      } else {
        updateTemplateFetch({
          id: dataForm.idWorkflow,
          body: {
            name: dataForm.name,
            status: false
          },
        })
      }
    } else {
      AlertForm({title: "Campos Incompletos!", text: "Por favor validar la información requerida", icon: ICON.INFO, timer: 3000})
    }
  }
  const validateFieldsToken = () => {
    let validateTitle = dataFormToken.title.length >= 3;
    let validateName = dataFormToken.name.length >= 3 && validateOnlyNumberCharactersGuionMedioBajo(dataFormToken.name);
    let validateDescription = dataFormToken.description.length >= 10;

    setErrorDataFormToken({
      title: !validateTitle ? 'Titulo debe tener 3 caracteres' : '',
      name: !(dataFormToken.name.length >= 3) ? 'Valor ID debe tener 3 caracteres' : !validateOnlyNumberCharactersGuionMedioBajo(dataFormToken.name) ? 'Valor ID No deben tener espacios, ni caracteres especiales(excepto - ó _)' : '',
      description: !validateDescription ? 'Descripción debe tener 10 caracteres' : '',
    });
    return validateName && validateDescription && validateTitle;
  }
  const validateFieldsCredentials = () => {
    let validateNode = dataFormCredentials.node;
    let validateType = dataFormCredentials.type.length >= 3;
    let validateDescription = dataFormCredentials.description.length >= 10;
    
    setErrorDataFormCredentials({
      node: !validateNode ? 'Seleccione un Nodo' : '',
      type: !validateType ? 'Seleccione un Tipo de Autenticacion' : '',
      description: !validateDescription ? 'Descripción debe tener 10 caracteres' : '',
    });
    return validateNode && validateDescription && validateType;
  }

  const handleAddToken = () => {
    let isValidForm = validateFieldsToken();
    let findMatchValue = dataForm.tokens.find(el => el.name === dataFormToken.name);
    if(findMatchValue) {
      return AlertForm({title: "Valor Duplicado!", text: "No se puede agregar token, el Valor ID ya existe!", icon: ICON.INFO})
    }
    if (isValidForm) {
      let id = window.crypto.randomUUID();
      let addToken = structuredClone(dataForm.tokens) ;
      addToken.push({...dataFormToken, id});
      setDataForm({...dataForm, tokens: addToken});
      setDataFormToken({title: '', name: '', description: '', id: ''});
    }
  }

  const handleAddCredentials = () => {
    let isValidForm = validateFieldsCredentials();
    let findMatchValue = dataForm.credentials.find(el => el.node === dataFormCredentials.node);
    if(findMatchValue) {
      return AlertForm({title: "Nodo Duplicado!", text: "No se puede agregar credencial, existe un Nodo con credencial, puedes editar la credencial!", icon: ICON.INFO})
    }
    if (isValidForm) {
      let id = window.crypto.randomUUID();
      let addCredentials = structuredClone(dataForm.credentials);
      addCredentials.push({...dataFormCredentials, id});
      setDataForm({...dataForm, credentials: addCredentials});
      setDataFormCredentials({node: '', type: '', description: '', id:''});
    }
  }

  const selectNode = (e) => {
    setDataFormCredentials({ ...dataFormCredentials, node: e.value });
  }
  const selectCredential = (e) => {
    setDataFormCredentials({ ...dataFormCredentials, type: e.value });
  }

  const handleDeleteToken = async(token) => {
    let result = await AlertConfirm({ title: 'Esta seguro de Eliminar token?', text: token.title });
    if (result.isConfirmed) {
      let deleteToken = dataForm.tokens.filter(el => el.id !== token.id);
      setDataForm({...dataForm, tokens: deleteToken});
    }
  }
  const handleDeleteCredential = async(credential) => {
    let result = await AlertConfirm({ title: 'Esta seguro de Eliminar credencial?', text: credential.type });
    if (result.isConfirmed) {
      let deleteToken = dataForm.credentials.filter(el => el.id !== credential.id);
      setDataForm({...dataForm, credentials: deleteToken});
    }
  }

  const handleEditToken = (token) => {
    setIsNewToken(false);
    setErrorDataFormToken({});
    setDataFormToken(token);
  }
  const handleUpdateToken = () => {
    let isValidForm = validateFieldsToken();
    if (isValidForm) {
      setIsNewToken(true);
      let clone = structuredClone(dataForm.tokens);
      let updateToken = clone.find(el => el.id === dataFormToken.id);
      updateToken.name = dataFormToken.name;
      updateToken.title = dataFormToken.title;
      updateToken.description = dataFormToken.description;
      setDataForm({...dataForm, tokens: clone});
      setDataFormToken({title: '', name: '', description: '', id: ''});
    }
   
  }
  const handleCancelToken = () => {
    setIsNewToken(true);
    setDataFormToken({title: '', name: '', description: '', id: ''});
    setErrorDataFormToken({});
  }
  const handleEditCredential = (credential) => {
    setIsNewCredential(false);

    setDataFormCredentials(credential);
  }
  const handleUpdateCredential = () => {
    let isValidForm = validateFieldsCredentials();
    if (isValidForm) {
      setIsNewCredential(true);
      let clone = structuredClone(dataForm.credentials);
      let updateCredential = clone.find(el => el.id === dataFormCredentials.id);
      updateCredential.node = dataFormCredentials.node;
      updateCredential.type = dataFormCredentials.type;
      updateCredential.description = dataFormCredentials.description;
      setDataForm({...dataForm, credentials: clone});
      setDataFormCredentials({node: '', type: '', description: '', id: ''});
    }
  }
  const handleCancelCredential = () => {
    setIsNewCredential(true);
    setDataFormCredentials({node: '', type: '', description: '', id: ''});
  }
  return (
    <TemplateStyles>
      <section className="templates-workflows">
        <Form onSubmit={handleCreateTemplate}>
          <h2>General</h2>
          <div className="row">
            <div className="form-control col-6">
              <label htmlFor="">Nombre Plantilla *</label>
              <InputForm
                placeholder={'Nombre'}
                type="text"
                name={'name'}
                onChange={changeDataTemplate}
                value={dataForm.name}
                error={errorDataForm.name ? true : false}
              />
                  <p className={`msg-error ${errorDataForm.name ? 'active' : ''}`}>{errorDataForm.name}</p>
            </div>
            <div className="form-control col-6">
              <label htmlFor="">Descripción Plantilla *</label>
              <InputForm
                placeholder={'Descripción'}
                type="text"
                name={'description'}
                onChange={changeDataTemplate}
                value={dataForm.description}
                error={errorDataForm.description ? true : false}
              />
                  <p className={`msg-error ${errorDataForm.description ? 'active' : ''}`}>{errorDataForm.description}</p>
            </div>
            <div className="form-control col-4">
              <label htmlFor="">Categorias</label>
              <div className="container-select">
              <Select
                options={[]}
                placeholder="Categoria Plantilla"
                theme={(theme) => styleTheme(theme)}
                
              />
            </div>
            </div>
          </div>
          <h2>Gestion Tokens</h2>
          <div className="row">
            <div className="form-control col-4">
              <label htmlFor="">Titulo Token *</label>
              <InputForm
                placeholder={'Titulo'}
                type="text"
                name={'title'}
                onChange={changeDataToken}
                value={dataFormToken.title}
                error={errorDataFormToken.title ? true : false}
              />
              <p className={`msg-error ${errorDataFormToken.title ? 'active' : ''}`}>{errorDataFormToken.title}</p>
            </div>
            <div className="form-control col-4">
              <label htmlFor="">Valor ID *</label>
              <InputForm
                placeholder={'Valor ID'}
                type="text"
                name={'name'}
                onChange={changeDataToken}
                value={dataFormToken.name}
                error={errorDataFormToken.name ? true : false}
              />
              <p className={`msg-error ${errorDataFormToken.name ? 'active' : ''}`}>{errorDataFormToken.name}</p>
            </div>
            <div className="form-control col-4">
              <label htmlFor="">Descripción *</label>
              <InputForm
                placeholder={'Descripción'}
                type="text"
                name={'description'}
                onChange={changeDataToken}
                value={dataFormToken.description}
                error={errorDataFormToken.description ? true : false}
              />
              <p className={`msg-error ${errorDataFormToken.description ? 'active' : ''}`}>{errorDataFormToken.description}</p>
            </div>
          </div>
          <div className='section-btn'>
            {isNewToken && <Button bgColor={"var(--green-color)"} bgColorHover={"var(--green-color)"} onPress={handleAddToken}>Agregar</Button>}
            {!isNewToken && 
            <>
              <Button bgColor={"var(--green-color)"} bgColorHover={"var(--green-color)"} onPress={handleUpdateToken}>Actualizar</Button>
              <Button onPress={handleCancelToken}>Cancelar</Button>
            </>}
          </div>
          <StyleTable cols="1fr 1fr 1.2fr 120px">
            <div className="header-table">
              <div>Nombre</div>
              <div>Valor</div>
              <div>Descripción</div>
              <div></div>
            </div>
            <div className="body-table">
            {dataForm.tokens?.map((token,idx) => {
              return (
                <div className="row-table" key={`token-${idx}`}>
                  <div className="col-table"> {token.title}</div>
                  <div className="col-table">{token.name}</div>
                  <div className="col-table">{token.description}</div>
                  <div className="col-table">
                    <DropdownMenuList img={Dots}>
                      <div className="item" onClick={() => handleEditToken(token)}>
                        <img src={Dots} alt="edit" />
                        <p>Editar</p>
                      </div>
                      <div className="item" onClick={() => handleDeleteToken(token)}>
                        <img src={Dots} alt="newAppoitment" />
                        <p>Eliminar</p>
                      </div>
                    </DropdownMenuList>
                  </div>
                </div>
              )})}
              {dataForm.tokens.length === 0 && <h4>No ha configurado ningun token</h4>}
            </div>
          </StyleTable>
          <h2>Gestion Credenciales</h2>
          <div className="row">
          <div className="form-control col-4">
            <label htmlFor="">Nodo *</label>
              <div className="container-select">
              <Select
                options={responseNodes}
                placeholder="Seleccione un Nodo"
                theme={(theme) => styleTheme(theme)}
                onChange={selectNode}
                isLoading={isLoadingNodes}
                value={responseNodes.find(el => el.value === dataFormCredentials.node) || { value: "", label: "" } }
              />
            </div>
            <p className={`msg-error ${errorDataFormCredentials.node ? 'active' : ''}`}>{errorDataFormCredentials.node}</p>
          </div>
          <div className="form-control col-4">
            <label htmlFor="">Tipo Autenticación *</label>
              <div className="container-select">
              <Select
                options={responseCredentials}
                placeholder="Seleccione un Tipo de credencial"
                theme={(theme) => styleTheme(theme)}
                onChange={selectCredential}
                isLoading={isLoadingCredentials}
                value={responseCredentials.find(el => el.value === dataFormCredentials.type) || { value: "", label: "" }}
              />
            </div>
            <p className={`msg-error ${errorDataFormCredentials.type ? 'active' : ''}`}>{errorDataFormCredentials.type}</p>
          </div>
            
            
            <div className="form-control col-4">
              <label htmlFor="">Descripción *</label>
              <InputForm
                placeholder={'Descripción'}
                type="text"
                name={'description'}
                onChange={changeDataCredentials}
                value={dataFormCredentials.description}
                error={errorDataFormCredentials.description ? true : false}
              />
                 <p className={`msg-error ${errorDataFormCredentials.description ? 'active' : ''}`}>{errorDataFormCredentials.description}</p>
            </div>
          </div>
          <div className='section-btn'>
            {isNewCredential && <Button bgColor={"var(--green-color)"} bgColorHover={"var(--green-color)"} onPress={handleAddCredentials}>Agregar</Button>}
            {!isNewCredential && 
            <>
              <Button bgColor={"var(--green-color)"} bgColorHover={"var(--green-color)"} onPress={handleUpdateCredential}>Actualizar</Button>
              <Button onPress={handleCancelCredential}>Cancelar</Button>
            </>}
          </div>
          <StyleTable cols="1fr 1fr 1.2fr 120px">
            <div className="header-table">
              <div>Nodo</div>
              <div>Tipo Autenticación</div>
              <div>Descripción</div>
              <div></div>
            
            </div>
            <div className="body-table">
            {dataForm.credentials.map((credential, idx) => {
              return (
                <div className="row-table" key={`credential-${idx}`}>
                  
                  <div className="col-table"> {credential.node}</div>
                    <div className="col-table">{credential.type}</div>
                    <div className="col-table">{credential.description}</div>
                  <div className="col-table">
                    <DropdownMenuList img={Dots}>
                      <div className="item" onClick={() => handleEditCredential(credential)}>
                        <img src={Dots} alt="edit" />
                        <p>Editar</p>
                      </div>
                      <div className="item" onClick={() => handleDeleteCredential(credential)}>
                        <img src={Dots} alt="newAppoitment" />
                        <p>Eliminar</p>
                      </div>
                    </DropdownMenuList>
                  </div>
                </div>
              )})}
            {dataForm.credentials.length === 0 && <h4>No ha configurado ninguna Credencial</h4>}
            </div>
          </StyleTable>

          <div className="text-center">
            <Button type="submit">{isCreate ? "Crear": "Editar"}</Button>
          </div>
        </Form>
      </section>
    </TemplateStyles>
  );
};
