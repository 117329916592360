import styled, { keyframes } from 'styled-components';
import { device } from '../../Constants/device';

const blinker = keyframes`
  from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
`;
export const StylesMenu = styled.nav`
  max-width: 200px;
  z-index: 99;
  & .hr-custom {
    border: 1px solid var(--darkgray-color);
  }
  & aside {
    width: 55px;
    background: #fff;
    padding: 0px 0px 10px 0px;
    position: fixed;
    left: 0px;
    top: 0;
    height: calc(100vh);
    box-shadow: 0px 3px 5px #6d6d6d;
    /*  overflow: hidden; */
    z-index: 99;
  }
  & aside ul {
    list-style: none;
    width: 100%;
    padding: 0;
  }
  & aside ul li {
    padding: 10px 16px;

    position: relative;
    margin: 0 0 0.5rem;
    & img {
      width: 24px;
    }
    &.logo-menu {
      padding-left: 0.5rem;

      & a {
        margin-bottom: 3rem;
        font-family: 'DM Sans Bold';
        font-size: 18px;
        & span {
          color: var(--primary-color);
        }
        & img {
          width: 40px;
          margin-right: 1rem;
        }
      }
    }
    & .header-dropdown {
      display: flex;
      align-items: center;
      gap: 0.6rem;
      & .img-user {
        width: 32px;
      }
      & .info-user {
        & .name-user {
          font-weight: bold;
        }
        & .email-user {
          font-size: 12px;
        }
      }
    }
  }
  & aside ul li a {
    font-size: 16px;
    color: #4a4a4a;
    text-decoration: none;
    /* text-transform: uppercase; */
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
  & aside ul li a span {
    display: inline-block;
    vertical-align: middle;
    color: #000000;
    width: 38px;
  }

  & aside ul li:hover:not(.logo-menu) {
    background: var(--lightgray-color);
    border-radius: 1rem;
  }
  & aside ul li.active {
    background: var(--lightpink-color) !important;
    border-radius: 1rem;
    font-weight: bold;
  }
  & aside ul li a span.notif {
    position: absolute;
    top: 8px;
    text-align: center;
    width: 30px;
    height: 30px;
    right: 10px;
    background: #343436;
    line-height: 31px;
    border-radius: 100%;
    color: #fff;
  }
  & .red {
    background: #e74c3c !important;
    box-shadow: 0px 0px 10px #e74c3c;
  }
  & .yellow {
    background: #f1c40f !important;
    box-shadow: 0px 0px 10px #f1c40f;
  }
  & .green {
    background: #27ae60 !important;
    box-shadow: 0px 0px 10px #27ae60;
  }
  & aside ul li a span.notif.green {
    width: 10px;
    height: 10px;
    color: transparent;
    right: 5px;
    transition: all 0.2s ease;
  }
  & aside ul li a span.notif.yellow {
    width: 10px;
    height: 10px;
    color: transparent;
    right: 5px;
    transition: all 0.2s ease;
  }
  & aside ul li a span.notif.red {
    width: 10px;
    height: 10px;
    color: transparent;
    right: 5px;
    transition: all 0.2s ease;
  }
  & aside ul li:hover a span.notif.red {
    width: 30px;
    height: 30px;
    right: 15px;
    color: #fff;
    box-shadow: 0px 0px 60px #e74c3c;
    top: 10px;
  }
  & aside ul li:hover a span.notif.yellow {
    width: 30px;
    height: 30px;
    right: 15px;
    color: #fff;
    box-shadow: 0px 0px 60px #f1c40f;
    top: 10px;
  }
  & aside ul li:hover a span.notif.green {
    width: 30px;
    height: 30px;
    right: 15px;
    color: #fff;
    box-shadow: 0px 0px 60px #27ae60;
    top: 10px;
  }
  & aside.keep {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.active-menu aside.keep,
  & aside.keep:hover {
    width: 200px;
  }
  &.active-menu aside.keep.th ul li a span.notif,
  & aside.keep:hover.th ul li a span.notif {
    width: 30px;
    height: 30px;
    right: 10px;
    color: #fff;
    box-shadow: 0px 0px 10px transparent;
    top: 8px;
  }

  &.active-menu aside ul li:not(.logo-menu),
  & aside:hover ul li:not(.logo-menu) {
    margin: 0 0.65rem 0.5rem;
  }
  & .name-menu,
  & .name-dropdown {
    display: none;
  }
  &.active-menu aside .name-menu,
  & aside:hover .name-menu,
  &.active-menu aside .name-dropdown,
  & aside:hover .name-dropdown {
    display: inline-block;
  }
  /*  */
  @-webkit-keyframes blinker {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  & .blink {
    text-decoration: blink;
    -webkit-animation-name: ${blinker};
    -webkit-animation-duration: 0.6s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-direction: alternate;
  }
  & p.blink.mah {
    text-align: center;
    line-height: 25;
    position: relative;
  }
  & p.blink.mah span {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 102, 153, 0);
    top: 248px;
    left: 360px;
  }
`;
