import styled from 'styled-components';
import iconError from '../../Assets/img/Login/Error.svg';

export const ContainerInput = styled.section`
  position: relative;
  & .icon-error {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    display: flex;
    align-items: center;
    & > div {
      width: 20px;
      height: 20px;
      background: url(${iconError}) no-repeat;
      background-size: cover;
      z-index: 10;
    }
  }

  & .icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 16px;
    display: flex;
    align-items: center;
    & > img {
      width: 20px;
      height: 20px;
      z-index: 10;
    }
  }
`;
export const StyleInput = styled.input`
  padding: 0.5rem 0.8rem;
  font-size: ${(props) => props.fontSize || '20px'};
  background-color: ${(props) => props.bgColor || '#fff'};
  border: 1px solid black;
  border-color: ${(props) => (props.error ? 'var(--input-border-red)' : 'var(--input-border-gray)')};
  margin: 8px 0;
  border-radius: 8px;
  width: 100%;

  &::placeholder {
    color: var(--darkgray-color);
  }
  &:focus-visible {
    outline-color: var(--primary-color);
  }
`;

export const StyleInputSecondary = styled.input`
  padding: 0.75rem 1rem 0.75rem 3rem;
  font-size: ${(props) => props.fontSize || '20px'};
  background-color: ${(props) => props.bgColor || '#fff'};
  border: 1px solid var(--darkgray-color);
  border-radius: 8px;
  margin: 8px 0;

  &::placeholder {
    color: var(--darkgray-color);
  }
  &:focus-visible {
    outline-color: var(--primary-color);
  }
`;
