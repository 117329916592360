import React from 'react';
import { GlobalStyle } from './Styles/globalStyles';

import { NavigationApp } from './Routers';

export const App = () => (
  <React.Fragment>
    <GlobalStyle />
    <NavigationApp />
  </React.Fragment>
);
