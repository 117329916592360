import React from 'react';
import { StylesBack } from './styles';
import Back from '../../Assets/img/Actions/Volver.svg';
export const BackLink = ({ onPress, disabled }) => {
  return (
    <StylesBack disabled={disabled} onClick={onPress}>
      <div onClick={onPress} className="img-back">
        <img src={Back} alt="back" />
      </div>
    </StylesBack>
  );
};
