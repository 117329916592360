import React, { useState } from 'react'
import { Button } from '../../../../Components/Buttons'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { ConfigStyles, StyleHubspot } from './styles'
import ConfigureMapping from '../Components/Configure/ConfigureMapping';
import ReviewMapping from '../Components/Configure/ReviewMapping';
import { AlertForm } from '../../../../Utils/Alert';

const ConfigMapping = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleBtnConfig = () => {
    if(tabIndex === 1) {
      AlertForm({title: "Cambios guardados Correctamente"});
    } else {
      setTabIndex(tabIndex + 1);
    }
  }
  return (
    <ConfigStyles>
      <div className='navbar-dark'>
        <div className='navbar-inner'>
          
          <div className='navbar-left'>
            <Button className={"hubspot btn-secondary"}>Exit </Button>
          </div>
          <div className='navbar-center'>
              <div className="text-center">
                <h4>Contact sync</h4>
                <small class="private-microcopy">
                  <ul class="uiList private-list--inline private-list--unstyled private-list--inline-divided">
                    <li class="uiListItem private-list__item"><span>Shopify Online</span></li>
                    <li class="uiListItem private-list__item"><span>Triario International Group LLC</span></li>
                  </ul>
                </small>

              </div>
          </div>
          <div className='navbar-right'>
              <h2>Sync is ON</h2>
              <Button className={"hubspot btn-active"} onPress={handleBtnConfig}>{tabIndex === 1 ? "Save": "Next"} </Button>
          </div>
        </div>
      </div>
      <StyleHubspot>
        <section className='section-general'>
          <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
            <TabList>
              <Tab className={"tab-item"}>1. Configure</Tab>
              <Tab className={"tab-item"}>2. Review</Tab>
            </TabList>
            <TabPanel>
               <ConfigureMapping />
            </TabPanel>
            <TabPanel>
              <ReviewMapping />
            </TabPanel>
          </Tabs>
        </section>
      </StyleHubspot>
    </ConfigStyles>
  )
}

export default ConfigMapping