import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const workflowsApi = createApi({
  reducerPath: 'workflowsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + '/workflows',
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem('user');
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllWorkflows: builder.query({
      query: () => '',
    }),
    createWorkflow: builder.mutation({
      query(body) {
        return {
          url: ``,
          method: 'POST',
          body,
        };
      },
    }),
    searchWorkflow: builder.query({
      query: (search) => `/${search}`,
    }),
    deleteWorkflow: builder.mutation({
      query(id) {
        return {
          url: `/${id}`,
          method: 'DELETE',
        };
      },
    }),
    filterWorkflow: builder.mutation({
      query(body) {
        return {
          url: `/filters`,
          method: 'POST',
          body,
        };
      },
    }),
    updateWorkflow: builder.mutation({
      query(data) {
        const { id, body } = data;
        return {
          url: `/${id}`,
          method: 'PUT',
          body,
        };
      },
    }),
    updateCoreWorkflow: builder.mutation({
      query(data) {
        const { id, body } = data;
        return {
          url: `/core/${id}`,
          method: 'PUT',
          body,
        };
      },
    }),
    syncWorkflow: builder.mutation({
      query(body) {
        return {
          url: `/sync`,
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const {
  useGetAllWorkflowsQuery,
  useCreateWorkflowMutation,
  useSearchWorkflowQuery,
  useDeleteWorkflowMutation,
  useFilterWorkflowMutation,
  useUpdateWorkflowMutation,
  useUpdateCoreWorkflowMutation,
  useSyncWorkflowMutation,
} = workflowsApi;
