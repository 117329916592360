import styled, { keyframes } from 'styled-components';
import Success from '../../../../../Assets/img/Actions/success.svg';

export const CheckboxStyles = styled.section`
  /* The switch - the box around the slider */
  z-index: 100;
  .switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 26px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f5f8fa;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 4px;
    border: 1px solid #cbd6e2;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: -1px;
    bottom: -1px;
    border: 1px solid #cbd6e2;
    border-radius: 4px;
    background-color: white;
    transition: all .3s;
    background: url(${Success}) no-repeat fixed center white;
  }

  input:checked + .slider {
    background-color: #2ca4bd;
    
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {

    transform: translateX(26px);
    border: 1px solid #2ca4bd;
  }
`
export const ConfigureStyles = styled.section`
  padding: 2rem 0;
  h4 {
      font-size: 16px;
      font-weight: 600;
    }
  p {
    font-weight: 300;
    line-height: 24px;
  }
  a {
    color: var(--primary-hubspot-color);
    text-decoration: none;
    font-weight: 600;
  }
  & .review {
    & .item-left, & .item-right {
          padding: 6px 20px;
      }
    & .item-center {
      opacity: 0.6
    }
    }
  & .sync-direction {
    margin-bottom: 2rem;
    & .title-main {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    & .section-sync {
      margin: 16px 0;
      & .grid {
        display: grid;
        grid-template-columns: 1fr 75px 1fr;
        column-gap: 30px;
        & .item-left, & .item-right {
          border: 1px solid rgb(223, 227, 235);
          align-self: stretch;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 15px 20px;
          & > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            & .image-app {
              max-width: 40px;
            }
            & .title-app {
              margin-left: 1.2rem;
            }
          }
        }
        & .item-center {
          & .hubspot.btn-secondary.light {
            padding: 8px;
          }
        }
      }
    }
  }

  & .custom-mapping {
    margin-top: 2rem;
    & .section-custom {
      width: 100%;
      border: 1px solid lightgray;
      padding: 1rem;
      & .section-custom-inner {
        display: flex;
        justify-content: center;
        gap: 5rem;
        & .select {
          width: 300px;
        }
        & .actions {
          display: flex;
          gap: 1rem;
        }
      }
    }
  }
`;
