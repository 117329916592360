import { ContainerInput, StyleInput } from './styles';

export const InputForm = ({
  disabled,
  type,
  bgColor,
  color,
  fontSize,
  placeholder,
  onChange,
  value,
  name,
  error = false,
  max,
  min
}) => (
  <ContainerInput>
    <StyleInput
      disabled={disabled}
      type={type}
      bgColor={bgColor}
      color={color}
      fontSize={fontSize}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      name={name}
      error={error}
      max={max}
      min={min}
    />
    <div className={error ? 'icon-error' : ''}>
      <div></div>
    </div>
  </ContainerInput>
);
