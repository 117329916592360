import React, { useEffect, useState } from 'react';

import { StyleTable } from './styles';
import Dots from '../../Assets/img/Actions/Dots.svg';
import { DropdownMenuList } from '../Dropdown/menuList';
import { AlertClose, AlertLoading } from '../../Utils/Alert';
import { PaginationMain } from '../Pagination';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import { formatDate } from '../../Utils/times';

export const TableClients = ({ dataClients = [], isLoading, openEditClient, deleteClient }) => {
  const [paginationClients, setPaginationClients] = useState([]);
  useEffect(() => {
    if (isLoading) {
      AlertLoading({ title: 'Cargando...' });
    } else {
      AlertClose();
    }
  }, [isLoading]);

  return (
    <>
      <StyleTable cols="40% 15% 25% 12% 8%">
        <div className="header-table">
          <div>Nombre</div>
          <div>Telefono</div>
          <div>Email</div>
          <div>Empresa</div>
          <div>Estado</div>
          <div></div>
        </div>
        <div className="body-table">
          {paginationClients.map((client) => {
            return (
              <div className="row-table" key={`client-${client.id}`}>
                <div className="col-table">
                  <div>
                    <div className="title">
                      {client.firstname} {client.lastname}
                    </div>
                    <div className="details">
                      <div className="detail-text">
                        Última actualización{' '}
                        {formatDistanceToNow(new Date(client.updatedAt), {
                          locale: es,
                          addSuffix: true,
                        })}
                      </div>
                      <div className="detail-text">Creado el {formatDate(client.createdAt.slice(0, -1))}</div>
                    </div>
                  </div>
                </div>
                <div className="col-table">
                  <p title={client.phone}>{client.phone || '--'}</p>
                </div>
                <div className="col-table">
                  <p title={client.email}>{client.email || '--'}</p>
                </div>
                <div className="col-table">
                  <p title={client.company}>{client.company || '--'}</p>
                </div>

                <div className="col-table">
                  <DropdownMenuList img={Dots}>
                    <div className="item" onClick={() => openEditClient(client)}>
                      <img src={Dots} alt="edit" />
                      <p>Editar</p>
                    </div>
                    <div className="item" onClick={() => deleteClient(client)}>
                      <img src={Dots} alt="newAppoitment" />
                      <p>Eliminar</p>
                    </div>
                  </DropdownMenuList>
                </div>
              </div>
            );
          })}
          {paginationClients.length === 0 && (
            <div className="not-results">
              <p>No Hay resultados!</p>
            </div>
          )}
        </div>
      </StyleTable>
      <PaginationMain data={dataClients} setDataPagination={setPaginationClients} numberItems={10} />
    </>
  );
};
