import styled from 'styled-components';

export const ModalContainer = styled.div`
  z-index: -10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--c-opacity);
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  &.is-open {
    display: flex;
    z-index: 999;
  }
  &.is-open .modal-container {
    transform: scale(1);
  }
  & .modal-container {
    transition: transform 1s ease;
    transform: scale(0.2);
    position: relative;
    background-color: var(--white-color);
    width: ${(props) => (props.size === 'xl' ? 'calc(100vw - 2rem)' : props.size === 'l' ? '90rem' : '64rem')};
    max-width: calc(100vw - 2rem);
    border-radius: 1rem;
    min-height: 200px;
    height: ${(props) => (props.size === 'xl' ? 'calc(100vh - 2rem)' : props.size === 'l' ? '80vh' : '50vh')};
    max-height: calc(100vh - 2rem);

    & header {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 30;
      background-color: ${(props) => props.color || 'var(--white-color)'};
      padding: 1rem 1.5rem;
      color: ${(props) => props.colorText || 'var(--primary-color)'};
      text-align: left;
      font-size: 28px;
      font-weight: bold;
      height: 62px;
      border-bottom: 1px solid var(--lightgray-color);
      border-radius: 1rem 1rem 0 0;
      & button {
        background-color: ${(props) => props.color || 'var(--white-color)'};
        border: none;
        color: var(--darkgray-color);
        transition: transform 0.5s ease;
        margin-top: -5px;
        &:hover {
          cursor: pointer;
          transform: rotate(90deg);
          & svg path {
            stroke: red;
          }
        }
      }
    }
    & section.content-modal {
      padding: 1rem;
      gap: 2rem;
      width: 100%;
      overflow: auto;
      height: calc(100% - 62px);
      & iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
  & .modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  & .modal-custom {
    & p.title {
      color: var(--primary-color);
      font-size: 25px;
      font-weight: 600;
      margin-top: 0;
      padding-left: 1rem;
    }
    & div.container {
      padding-left: 2.5rem;
    }

    &.border {
      border-right: 1px solid var(--lightgray-color);
      padding-right: 1rem;
    }
  }
  & .empty {
    height: 18px;
  }
`;
