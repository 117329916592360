import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { Button } from '../../../../Components/Buttons';
import { LinkStyles, StateStyle } from '../../../../Components/Table/HubSpot/styles';
import { TableHubSpot } from '../../../../Components/Table/HubSpot/tableHubSpot';
import { formatDate } from '../../../../Utils/times';
import { dataObjects } from '../Mapping/dataDummy';


export const ObjectView = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState(dataObjects);
  let navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        cell: ({ row, getValue }) => {
          return <>
              <LinkStyles onClick={() => navigate(`/apps/mapping/${row.original.id}`) }>
                {getValue()}
              </LinkStyles>
              <div>
                <StateStyle className={row.original.active ? "on": "off"}></StateStyle>
                <span>{row.original.active ? "On": "Off"}</span>
              </div>         
            </>
        },
      },
      {
        accessorFn: row => row.inSync,
        id: 'inSync',
        cell: info => info.getValue().toLocaleString("en"),
        header: () => <span>In Sync</span>,
        meta: {
          filterVariant: 'range',
        },
      },
      {
        id: 'failing',
        accessorKey: 'failing',
        header: () => 'Failing',
        cell: info => info.getValue().toLocaleString("en"),
        meta: {
          filterVariant: 'range',
        },
      },
      {
        id: 'lastEdited',
        accessorKey: 'lastEdited',
        header: () => <span>Last Edited</span>,
        cell: ({ row, getValue }) => {
          return <>
              <span> {formatDate(getValue().updatedAt)} </span>
              <div style={{fontSize: "12px"}}>
                {getValue().name} 
              </div>         
            </>
        },
      },
    ],
    []
  )
    
  const handleOpenTab = () => {
    window.open(window.location.href, '_blank').focus();
    setTimeout(() => {
      window.parent.postMessage(JSON.stringify({"action": "DONE"}), "*");
    }, 1000);
  }
  return (
   <>
    <h2 className='h2'>Object View</h2>
    <section>
      <div className='section-btn'>
        <Button className={"hubspot button--sm"} onPress={() => setShowFilter(!showFilter)}>{showFilter ? "Hidden" : "Show"} filters</Button>
        <Button className={"hubspot button--sm"}>Sync now</Button>
        <Button className={"hubspot button--sm"} onPress={handleOpenTab}>New tab</Button>
      </div>
      <div>
        <TableHubSpot dataTable={data} showFilter={showFilter} columns={columns} rowExpand={false} />
      </div>
    </section>
   </>
  );
};
