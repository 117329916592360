 export const dataObjects = [
    {
        name: "Contactos",
        id:"contact",
        active: true,
        inSync: 1232,
        failing: 22,
        lastEdited: {
            name: "Juan López",
            updatedAt: "2024-08-23T13:27:20.549Z"
        }
    },
    {
        name: "Productos",
        id:"product",
        active: true,
        inSync: 43243,
        failing: 44,
        lastEdited: {
            name: "Juan López",
            updatedAt: "2024-08-20T08:27:20.549Z"
        }
    },
    {
        name: "Negocios",
        id:"deal",
        active: false,
        inSync: 343,
        failing: 12,
        lastEdited: {
            name: "Juan López",
            updatedAt: "2024-08-17T05:45:20.549Z"
        }
    },
]

export const dataRecord = [
    {

        name: "Juan López",
        email: "juan.lopez@triario.com",
        url: "https://app.hubspot.com/contacts/711526/contact/52333019988",
        object: "Contact",
        status: "inSync",
        activityDate: "2024-08-23T13:27:20.549Z",
        observation: {
            title: "Matching",
            detail: ""
        },
        source: "HubSpot",
        workflowID: "dasfWfds1",
        executionId: 23226
    },
    {
        name: "David Perez",
        email: "david.perez@triario.com",
        url: "https://app.hubspot.com/contacts/711526/contact/52333019988",
        object: "Contact",
        status: "failing",
        activityDate: "2024-08-22T10:56:20.549Z",
        observation: {
            title: "Other Error",
            detail: "Subscription period has ended or canceled or there was a billing problem : You can't add data to QuickBooks Online Plus because your trial or subscription period ended, you canceled your subscription, or there was a billing problem. To update your subscription, click the gear icon and view your account information."
        },
        source: "HubSpot",
        workflowID: "dasfWfds1",
        executionId: 23225
    },
    {
        name: "Daniel Arango",
        email: "daniel.arango@triario.com",
        url: "https://app.hubspot.com/contacts/711526/contact/52333019988",
        object: "Contact",
        status: "inSync",
        activityDate: "2024-08-02T10:56:20.549Z",
        observation: {
            title: "Matching",
            detail: ""
        },
        source: "HubSpot",
        workflowID: "dasfWfds1",
        executionId: 23230
    },
    {
        name: "Daniela Arango",
        email: "daniela.arango@triario.com",
        url: "https://app.hubspot.com/contacts/711526/contact/52333019988",
        object: "Contact",
        status: "inSync",
        activityDate: "2024-08-02T10:56:20.549Z",
        observation: {
            title: "Matching",
            detail: ""
        },
        source: "HubSpot",
        workflowID: "dasfWfds1",
        executionId: 23224
    },
    {
        name: "Juan López #3232",
        email: null,
        url: "https://app.hubspot.com/contacts/711526/deal/21761509749",
        object: "Deal",
        status: "inSync",
        activityDate: "2024-08-10T10:56:20.549Z",
        observation: {
            title: "Matching",
            detail: ""
        },
        source: "Shopify",
        workflowID: "dasfWfds1",
        executionId: 23223
    },
    {
        name: "Daniel Arango #3233",
        email: null,
        url: "https://app.hubspot.com/contacts/711526/deal/21761509749",
        object: "Deal",
        status: "failing",
        activityDate: "2024-07-10T10:56:20.549Z",
        observation: {
            title: "Error",
            detail: "Error API"
        },
        source: "Shopify",
        workflowID: "dasfWfds1",
        executionId: 23222
    },
    {
        name: "Portatil 14inch",
        email: null,
        url: "https://app.hubspot.com/contacts/711526/objects/0-7/views/all/list?query=99-OTR",
        object: "Product",
        status: "inSync",
        activityDate: "2024-08-15T21:56:20.549Z",
        observation: {
            title: "Matching",
            detail: ""
        },
        source: "Shopify",
        workflowID: "dasfWfds1",
        executionId: 23220
    },
    {
        name: "TV 70inch Full HD",
        email: null,
        url: "https://app.hubspot.com/contacts/711526/objects/0-7/views/all/list?query=10-INT%20",
        object: "Product",
        status: "failing",
        activityDate: "2024-08-10T15:56:20.549Z",
        observation: {
            title: "Error",
            detail: "Error API Product"
        },
        source: "HubSpot",
        workflowID: "dasfWfds1",
        executionId: 23219
    },
    {
        name: "TV 60inch Full HD",
        email: null,
        url: "https://app.hubspot.com/contacts/711526/objects/0-7/views/all/list?query=10-INT",
        object: "Product",
        status: "failing",
        activityDate: "2024-08-10T15:56:20.549Z",
        observation: {
            title: "Error",
            detail: ""
        },
        source: "HubSpot",
        workflowID: "dasfWfds1",
        executionId: 23219
    },
    {
        name: "Portatil 14inch",
        email: null,
        url: null,
        object: "Product",
        status: "inSync",
        activityDate: "2024-08-15T21:56:20.549Z",
        observation: {
            title: "Matching",
            detail: ""
        },
        source: "Shopify",
        workflowID: "dasfWfds1",
        executionId: 23220
    },
    {
        name: "TV 70inch Full HD",
        email: null,
        url: null,
        object: "Product",
        status: "failing",
        activityDate: "2024-08-10T15:56:20.549Z",
        observation: {
            title: "Error",
            detail: "Error API Product"
        },
        source: "HubSpot",
        workflowID: "dasfWfds1",
        executionId: 23219
    },
    {
        name: "TV 60inch Full HD",
        email: null,
        url: "https://app.hubspot.com/contacts/711526/objects/0-7/views/all/list?query=10-INT",
        object: "Product",
        status: "failing",
        activityDate: "2024-08-10T15:56:20.549Z",
        observation: {
            title: "Error",
            detail: ""
        },
        source: "HubSpot",
        workflowID: "dasfWfds1",
        executionId: 23219
    },
]

export const dataMapping = [
    {
        active: true,
        app_property: {
            id: "firstname",
            label: "First Name"
        },
        hs_property: {
            id: "firstname",
            label: "First Name"
        },
        type: "default",
        direction: "app_hs",  // -> app_hs, hs_app, bidirecctional
    },
    {
        active: true,
        app_property: {
            id: "lastname",
            label: "Last Name"
        },
        hs_property: {
            id: "lastname",
            label: "Last Name"
        },
        type: "default",
        direction: "hs_app",  // -> app_hs, hs_app, bidirecctional
    },
    {
        active: true,
        app_property: {
            id: "title",
            label: "Title"
        },
        hs_property: {
            id: "name",
            label: "Name"
        },
        type: "default",
        direction: "bidirecctional",  // -> app_hs, hs_app, bidirecctional
    },
    {
        active: false,
        app_property: {
            id: "title",
            label: "Title"
        },
        hs_property: {
            id: "name",
            label: "Name"
        },
        type: "default",
        direction: "bidirecctional",  // -> app_hs, hs_app, bidirecctional
    },
]

export const dataTransactions = [
    {
        "category": "vtex_abandoned_cart",
        "count": "43"
    },
    {
        "category": "vtex_create_order",
        "count": "591"
    },
    {
        "category": "all",
        "count": 634
    }
]