import { DropdownContainerMenu } from './styles';
import React, { useState, useEffect } from 'react';
import { Button } from '../Buttons';

export const DropdownMenuList = ({
  children,
  title = '',
  bgButtonDropdown = 'white',
  color = 'black',
  bgColor = 'white',
  border,
  img: Img,
  text,
  notification = 0,
  location = 'down',
  type = 'normal',
  allowInternalClick = false
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeAll = () => {
    setIsOpen(false);
  };

  const handleDropdown = (e) => {
    e.stopPropagation();
    document.body.click();
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    setTimeout(() => {
      if (isOpen) {
        window.addEventListener('click', closeAll);
      } else {
        window.removeEventListener('click', closeAll);
      }
    }, 0);
  }, [isOpen]);

  const handleSection = (e) => {
    e.stopPropagation();
    if (!allowInternalClick) {
      document.body.click();
    }
  };
  return (
    <DropdownContainerMenu
      bgButtonDropdown={bgButtonDropdown}
      color={color}
      bgColor={bgColor}
      border={border}
      location={location}
      notification={notification}
    >
      <div onClick={handleDropdown}>
        <span>
          {type === 'normal' && <img src={Img} alt="Config" className="actions" />}
          {type === 'button' && (
            <Button>
              <img src={Img} alt="Img" />
            </Button>
          )}
          {type === 'button-hs' && (
            <Button className={"hubspot button--sm"}>
              <div style={{display: "flex", alignItems: "center"}}>
                <span>{title}</span>  <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M8 9h8l-4 7"/></svg>
              </div>
            </Button>
          )}
          {type === 'button-hs-secondary' && (
            <Button className={"hubspot btn-secondary light"}>
              <div style={{display: "flex", alignItems: "center"}}>
                <span>{title}{Img && <img src={Img} alt="Img" width={"30"} />}</span>  <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M8 9h8l-4 7"/></svg>
              </div>
            </Button>
          )}
        </span>
        <span className="name-dropdown">{text}</span>
        {notification ? <span className="notif green">{notification}</span> : ''}
      </div>
      <section className={`dropdown ${isOpen && 'is-open'}`} onClick={handleSection}>
        {children}
      </section>
    </DropdownContainerMenu>
  );
};
