import Swal from 'sweetalert2';
export const ICON = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  QUESTION: 'question',
};

export const AlertForm = ({
  title = '',
  text = '',
  icon = ICON.INFO,
  position = 'center',
  timer = 7000,
  showConfirmButton = true,
}) => {
  const Toast = Swal.mixin({
    toast: true,
    position,
    showConfirmButton,
    timer,
    timerProgressBar: true,
    showClass: {
      popup: 'animate__animated animate__backInDown',
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp',
    },
  });

  Toast.fire({
    icon,
    title,
    text,
  });
};

export const AlertFormLarge = ({ title = '', text = '', icon = ICON.INFO, footer = null }) => {
  Swal.fire({
    icon,
    title,
    text,
    footer,
  });
};

export const AlertConfirm = async ({
  title = '',
  text = '',
  icon = ICON.WARNING,
  textButton = 'Si',
  showCancelButton = true,
}) => {
  let result = await Swal.fire({
    title,
    text,
    icon,
    showCancelButton: showCancelButton,
    confirmButtonColor: 'var(--blue-dark)',
    cancelButtonColor: 'var(--red-color)',
    confirmButtonText: textButton,
    cancelButtonText: 'No',
    allowOutsideClick: false,
  });
  return result;
};

export const AlertConfirmThree = async ({ title = '', html = '', textButton = 'Yes', textDeny = 'No' }) => {
  let result = await Swal.fire({
    title,
    html,
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: textButton,
    denyButtonText: textDeny,
    allowOutsideClick: false,
  });
  return result;
};

export const AlertHTML = ({ title = '', html = '', icon = ICON.INFO, footer = null }) => {
  Swal.fire({
    icon,
    title,
    html,
    footer,
  });
};

export const AlertConfirmHTML = async({
  title = '',
  html = '',
  icon = ICON.WARNING,
  textButton = 'Si',
}) => {
  let result = await Swal.fire({
    title,
    html,
    icon,
    showCancelButton: true,
    confirmButtonColor: 'var(--blue-dark)',
    cancelButtonColor: 'var(--red-color)',
    confirmButtonText: textButton,
    cancelButtonText: 'No',
    allowOutsideClick: false,
  })
  return result;
};

export const AlertLoading = ({ title = 'Guardando...', text = '', allowOutsideClick = false }) => {
  Swal.fire({
    title,
    text,
    allowOutsideClick,
  });
  Swal.showLoading();
};

export const AlertClose = () => {
  Swal.close();
};
