import React, { useEffect, useState } from 'react';

import { StyleTable } from './styles';
import Dots from '../../Assets/img/Actions/Dots.svg';
import { DropdownMenuList } from '../Dropdown/menuList';
import { PaginationMain } from '../Pagination';
import { AlertClose, AlertConfirm, AlertForm, AlertLoading, ICON } from '../../Utils/Alert';
import { Modal } from '../Modals';
import { useModal } from '../../Hooks/useModal';
import { Button } from '../Buttons';

import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import { formatDate } from '../../Utils/times';

import { TemplateManagement } from '../../Template/AdministrarPlantilla';
import { FormDescription } from '../FormDescription';
import { useDeleteWorkflowMutation, useFilterWorkflowMutation } from '../../Store/api/workflows';
import { TableList } from './listWorkflows';


export const Table = ({ dataWorkflow, isLoading, filterWorkflowFetch }) => { 
  const [isOpenModalEdit, openModalEdit, closeModalEdit] = useModal(false);
  const [isOpenModalDescription, openModalDescription, closeModalDescription] = useModal(false);
  const [isOpenModalListWF, openModalListWF, closeModalListWF] = useModal(false);
  let [deleteWorkflowFetch, { isLoading: isLoadingDelete, isSuccess: isSuccessDelete, isError: isErrorDelete, error: errorDelete }] = useDeleteWorkflowMutation();
  let [getWorkflowIdParentFetch, { isLoading: isLoadingWFParent, isSuccess: isSuccessWFParent, data: getDataWFParent, isError: isErrorWFParent}] = useFilterWorkflowMutation();
  let [getWorkflowIdParentValidateFetch, { isLoading: isLoadingValidate, isSuccess: isSuccessValidate, data: getDataValidate, isError: isErrorValidate}] = useFilterWorkflowMutation();
  const [paginationWorkFlow, setPaginationWorkFlow] = useState([]);
  const [workflowCore, setWorkflowCore] = useState(null);
  const [dataTemplate, setDataTemplate] = useState(null);
  const [dataTemplateDelete, setDataTemplateDelete] = useState(null);


  useEffect(() => {
    if (!isOpenModalDescription) {
      setWorkflowCore(null);
    }
  }, [isOpenModalDescription]);

  useEffect(() => {
    if (isSuccessDelete) {
      AlertForm({ title: 'Plantilla eliminado correctamente...', icon: ICON.SUCCESS });
      filterWorkflowFetch({ term: '' });
    } else if (isErrorDelete) {
      AlertForm({
        title: 'Hubo un inconveniente al Eliminar Plantilla',
        text: errorDelete.data?.message || '',
        icon: ICON.WARNING,
      });
    } else if (isLoadingDelete) {
      AlertLoading({ title: 'Eliminando Plantilla...' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingDelete, isSuccessDelete, isErrorDelete]);

  useEffect(() => {
    if (isSuccessWFParent) {
      openModalListWF();
      AlertClose();
    } else if (isErrorWFParent) {
      AlertForm({
        title: 'Hubo un inconveniente al listar Worflows',
        text: '',
        icon: ICON.WARNING,
      });
    } else if (isLoadingWFParent) {
      AlertLoading({ title: 'Obteniendo lista...' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingWFParent, isSuccessWFParent, isErrorWFParent]);

  useEffect(() => {
    const confirmDelete = async () => {
      let result = await AlertConfirm({ title: 'Esta seguro de Eliminar template?', text: `Template Nombre: ${dataTemplateDelete.name}` });
      if (result.isConfirmed) {
        deleteWorkflowFetch(dataTemplateDelete.id);
      }
    };
    if (isSuccessValidate) {
      if(getDataValidate.data.length === 0) {
        confirmDelete();
      } else {
        AlertForm({
          title: 'No se puede eliminar template, tiene Workflows asociados!',
          text: '',
          icon: ICON.WARNING,
        });
      }
      
    } else if (isErrorValidate) {
      AlertForm({
        title: 'Hubo un inconveniente al validar lista de Worflows asociados al template',
        text: 'No se puede eliminar, intentalo mas tarde',
        icon: ICON.WARNING,
      });
    } else if (isLoadingValidate) {
      AlertLoading({ title: 'Validando Asociaciones..' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingValidate, isSuccessValidate, isErrorValidate]);

  const handleOpenWorkflow = (id, restrintion) => {

    if(restrintion){
      //AlertForm({title: "Restricción para abrir!", text: "El workflow esta asociado a un template y no es posible abrir el editor", icon: ICON.INFO})
    } 
    let urlN8N = `${process.env.REACT_APP_N8N_API_URL}/workflow/${id.idWorkflow}`;

    const win = window.open(urlN8N, '_blank');
    win.focus();
  };

  const handleOpeneditModal = (workflow) => {
    setDataTemplate(workflow);
    openModalEdit();
  };

  const handleDeleteWorkflow = async ({id, name}) => {
    setDataTemplateDelete({id, name});
    getWorkflowIdParentValidateFetch({ idParent: id })
    
  };

  const handleDescription = (workflow) => {
    setWorkflowCore(workflow);
    openModalDescription();
  }

  const handleGetListWorkflows = (id) => {
    getWorkflowIdParentFetch({ idParent: id });
  }


  return (
    <>
      <StyleTable cols="1.5fr 1.5fr 1fr 100px">
        <div className="header-table">
          <div>Nombre</div>
          {/* <div>Creado por:</div> */}
          <div>Descripción</div>
          <div>Workflows Asociados</div>
          <div>Acciones</div>
        </div>
        <div className="body-table">
          {paginationWorkFlow.map((workflow) => {
            return (
              <div className="row-table" key={`workflow-${workflow.id}`}>
                <div className="col-table">
                  <div>
                    <div className="title">{workflow.name}</div>
                    <div className="details">
                      <div className="detail-text">
                        Última actualización{' '}
                        {formatDistanceToNow(new Date(workflow.updatedAt), {
                          locale: es,
                          addSuffix: true,
                        })}
                      </div>
                      <div className="detail-text">Creado el {formatDate(workflow.createdAt)}</div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-table">
                  <div className='action-btn'>
                    {workflow.author}
                  </div>
                </div> */}
                <div className="col-table">
                  {workflow.description}
                </div>
                <div className="col-table">
                  <div className='action-btn'>
                    <Button onPress={() => handleGetListWorkflows(workflow.id)} fontSize={"0.8rem"}>Lista Workflows</Button>
                  </div>
                </div>
                <div className="col-table">
                  <DropdownMenuList img={Dots}>
                    <div className="item" onClick={() => handleOpeneditModal(workflow)}>
                      <img src={Dots} alt="edit" />
                      <p>Editar</p>
                    </div>
                    <div className="item" onClick={() => handleOpenWorkflow(workflow, workflow.idParent)}>
                      <img src={Dots} alt="edit" />
                      <p>Abrir</p>
                    </div>

                    <div className="item" onClick={() => handleDeleteWorkflow(workflow)}>
                      <img src={Dots} alt="newAppoitment" />
                      <p>Eliminar</p>
                    </div>
                    <div className="item" onClick={() => handleDescription(workflow)}>
                      <img src={Dots} alt="executions" />
                      <p>Descripciones</p>
                    </div>
                  </DropdownMenuList>
                </div>
              </div>
            );
          })}
         
           {isLoading && paginationWorkFlow.length === 0 &&
             <div className="not-results">
              <h3>Cargando Resultados!</h3>
            </div>
          }
          {!isLoading && paginationWorkFlow.length === 0 && (
            <div className="not-results">
              <h3>No Hay resultados!</h3>
            </div>
          )}
        </div>
      </StyleTable>
      <PaginationMain data={dataWorkflow} setDataPagination={setPaginationWorkFlow} numberItems={10} />

      <Modal
        title="Editar Plantilla"
        isOpen={isOpenModalEdit}
        closeModal={closeModalEdit}
        size="l"
        closeConfirm={true}
      >
        <TemplateManagement
          dataTemplate={dataTemplate}
          closeModal={closeModalEdit}
          isOpenModal={isOpenModalEdit}
          filterWorkflowFetch={filterWorkflowFetch}
        />
      </Modal>
      <Modal
        title="Control de versiones de Descripciones"
        isOpen={isOpenModalDescription}
        closeModal={closeModalDescription}
        size="m"
        closeConfirm={true}
      >
        <FormDescription workflowCore={workflowCore} filterWorkflowFetch={filterWorkflowFetch} closeModal={closeModalDescription}/>

      </Modal>
      <Modal
        title="Lista de Workflows"
        isOpen={isOpenModalListWF}
        closeModal={closeModalListWF}
        size="m"
        closeConfirm={false}
      >
        <TableList dataListWorkflow={getDataWFParent?.data || []}></TableList>
      </Modal>   
    </>
  );
};
