import React, { useEffect, useState } from 'react';
import { StyleHubspot } from './styles';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { ObjectView } from '../Components/ObjectView';
import { RecordView } from '../Components/RecordView';
import { useValidationTokenQuery } from '../../../../Store/api/apps';
import { useNavigate } from 'react-router-dom';

export const Mapping = () => {
  const [showFilter, setShowFilter] = useState(false)
  let { data: dataValidation, error: errorValidation, isLoading } = useValidationTokenQuery();
  let navigate = useNavigate();

  useEffect(() => {
    if(errorValidation) {
      if(errorValidation.status === 403) {
        navigate('/');
      }
    }
  }, [errorValidation])
  return (
    <StyleHubspot>
      <section className='section-general'>
        <Tabs>
          <TabList>
            <Tab className={"tab-item"}>Object View</Tab>
            <Tab className={"tab-item"}>Record View</Tab>
          </TabList>

          <TabPanel>
            <ObjectView />
          </TabPanel>
          <TabPanel>
            <RecordView />
          </TabPanel>
        </Tabs>
      </section>
    </StyleHubspot>
  );
};
