import React, { useEffect, useState, useMemo } from 'react';
import 'react-tabs/style/react-tabs.css';
import { Button } from '../../../../Components/Buttons';
import { LinkStyles, StateStyle } from '../../../../Components/Table/HubSpot/styles';
import { TableHubSpot } from '../../../../Components/Table/HubSpot/tableHubSpot';
import { capitalLetter } from '../../../../Utils/String';
import { formatDate } from '../../../../Utils/times';
import { dataRecord } from '../Mapping/dataDummy';


export const RecordView = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState(dataRecord);
  const handleOpenWindow = (url) => {
    if(!url) return;
    window.open(url, "_blank");
  }

  const handleOpenTab = () => {
    window.open(window.location.href, '_blank').focus();
    window.parent.postMessage(JSON.stringify({"action": "DONE"}), "*");
  }
  
  const columns = useMemo(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        cell: ({ row, getValue }) => {
          return <>
              {
                row.original.url ? <LinkStyles onClick={() =>handleOpenWindow(row.original.url)}>
                {getValue()} <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 14 14"><path fill="#9a9a9a" fillRule="evenodd" d="M7 2H2.5a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7a1 1 0 1 1 2 0v4.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 0 11.5v-9A2.5 2.5 0 0 1 2.5 0H7a1 1 0 0 1 0 2M9.538.309A.5.5 0 0 1 10 0h3.5a.5.5 0 0 1 .5.5V4a.5.5 0 0 1-.854.354L12.104 3.31L7.707 7.707a1 1 0 0 1-1.414-1.414l4.396-4.397L9.646.854a.5.5 0 0 1-.108-.545" clipRule="evenodd"/></svg>
              </LinkStyles>: <span>{getValue()}</span>
              }
              
              <div>
                {
                  row.original.email ?? <span>{row.original.email}</span>
                }
              </div>         
            </>
        },
      },
     
      {
        accessorFn: row => row.object,
        id: 'object',
        cell: info => info.getValue(),
        header: () => <span>Object</span>,
        options: [{id: "Contact", label: "Contact"}, {id: "Deal", label: "Deal"}, {id: "Product", label: "Product"}],
        meta: {
          filterVariant: 'select',
        },
      },
      {
        id: 'status',
        accessorKey: 'status',
        cell: ({ row, getValue }) => {
          return <>
              
               <StateStyle className={getValue() === "inSync" ? "on": "error"}></StateStyle>
                <span>{capitalLetter(getValue())}</span>     
            </>
        },
        options: [{id: "inSync", label: "InSync"}, {id: "failing", label: "Failing"}],
        meta: {
          filterVariant: 'select',
        },
      },
      {
        id: 'activityDate',
        accessorKey: 'activityDate',
        header: () => 'Activity Date',
        cell: info => formatDate(info.getValue()),
        meta: {
          filterVariant: 'hidden',
        },
      },
      {
        id: 'observation',
        accessorKey: 'observation',
        header: () => <span>Sync Activity</span>,
        cell: ({ row, getValue }) => {
          return row.getCanExpand() ? (
            <>
              <div className='expand-section'>
                <button className='btn-expand'
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                    style: { cursor: 'pointer' },
                  }}
                >
                  {row.getIsExpanded() ? 
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.4em" height="1.4em" viewBox="0 0 24 24"><path fill="var(--primary-hubspot-color)" d="M16.939 7.939L12 12.879l-4.939-4.94l-2.122 2.122L12 17.121l7.061-7.06z"/></svg>
                    <span>{getValue().title}</span>
                  </> : 
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1.4em" height="1.4em" viewBox="0 0 24 24"><path fill="var(--primary-hubspot-color)" d="M10.061 19.061L17.121 12l-7.06-7.061l-2.122 2.122L12.879 12l-4.94 4.939z"/></svg>
                    <span>{getValue().title}</span>
                  </>
                  }

                </button>
              </div>      
            </>
          ) : (
            <>
              <span>{getValue().title}</span>
            </>
          )
        },
        meta: {
          filterVariant: 'hidden',
        },
      },
      {
        id: 'source',
        accessorKey: 'source',
        header: () => <span>Source</span>,
        cell: info => info.getValue(),
        options: [{id: "HubSpot", label: "HubSpot"}, {id: "Shopify", label: "Shopify"}],
        meta: {
          filterVariant: 'select',
        },
      },
    ],
    []
  )
  return (
   <>
    <h2 className='h2'>Record View</h2>
    <section>
      <div className='section-btn'>
        <Button className={"hubspot button--sm"} onPress={() => setShowFilter(!showFilter)}>{showFilter ? "Hidden" : "Show"} filters</Button>
        <Button className={"hubspot button--sm"}>Sync now</Button>
        <Button className={"hubspot button--sm"} onPress={handleOpenTab}>New tab</Button>
      </div>
      <div>
        <TableHubSpot dataTable={data} showFilter={showFilter} columns={columns} rowExpand={true} subComponent={RenderSubComponent}/>
      </div>
    </section>
   </>
  );
};


const RenderSubComponent = ({row}) => {
  return <section>
    <h4>Detalle:</h4>
    <p>{row.original.status === "inSync" ? "Datos sincronizado correctamente" : row.original.observation?.detail || "Sin datos del error"}</p>
    {row.original.status !== "inSync" ? <p style={{marginTop: "1rem"}}><b> Data Workflow:</b> {row.original.workflowID}</p>: ""}
    {row.original.status !== "inSync" ? <p> <b>Data Execution ID:</b>  {row.original.executionId}</p>: ""}
  </section>
}