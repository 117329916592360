export const styleTheme = (theme) => {
  return {
    ...theme,
    borderRadius: 6,
    colors: {
      ...theme.colors,
      primary25: 'var(--lightgray-color)',
      primary: 'var(--primary-color)',
    },
  };
};
export const styleThemeHS = (theme) => {
  return {
    ...theme,
    borderRadius: 6,
    colors: {
      ...theme.colors,
      primary25: 'var(--lightgray-color)',
      primary: 'var(--primary-hubspot-color)',
    },
  };
};
