import { AlertConfirm } from '../../Utils/Alert';
import { ModalContainer } from './style';
import Close from '../../Assets/img/Actions/Cerrar.svg';
/**
 *
 *
 * @size Tamaño del modal, opciones xl - l - m
 *  */

export const Modal = ({ children, isOpen, closeModal, title = '', color, colorText, size = "m", closeConfirm = false }) => {
  const handleModalContainerClick = (e) => e.stopPropagation();

  const handleClose = async () => {
    if (closeConfirm) {
      let result = await AlertConfirm({
        title: 'Estas seguro de cerrar la ventana?',
        text: '',
      });
      if (result.isConfirmed) {
        closeModal();
      }
    } else {
      closeModal();
    }
  };
  return (
    <ModalContainer className={`modal ${isOpen && 'is-open'}`} color={color} colorText={colorText} size={size}>
      <div className="modal-container" onClick={handleModalContainerClick}>
        <header>
          {title}
          <button className="modal-close" onClick={handleClose}>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="40" height="40" rx="8" fill="white" />
              <path d="M26 14L14 26" stroke="#999999" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M14 14L26 26" stroke="#999999" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
        </header>
        <section className="content-modal">{children}</section>
      </div>
    </ModalContainer>
  );
};
