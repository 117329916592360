import React, { useState } from 'react'
import { DropdownMenuList } from '../../../../../Components/Dropdown/menuList';
import { MappingTable } from './MappingTable';
import { ConfigureStyles } from './styles';
import Dots from '../../../../../Assets/img/Actions/Dots.svg';
import Bidirectional from '../../../../../Assets/img/Directions/bidirectional.svg';
import Left from '../../../../../Assets/img/Directions/left.svg';
import Right from '../../../../../Assets/img/Directions/right.svg';
import { Button } from '../../../../../Components/Buttons';
import { styleTheme, styleThemeHS } from '../../../../../Styles/SelectTheme';
import ReactSelect from 'react-select';

const ConfigureMapping = () => {

  const [selectOption, setSelectOption] = useState(Bidirectional);
  const [customMapping, showCustomMapping] = useState(false);

  
  return (
    <ConfigureStyles>
      <div className='sync-direction'>
        <h4>Sync direction</h4>
        <div className='section-sync'>
          <div className="grid">
              <div className='item-left'>
                <div>
                    <div className='image-app'>
                      <img src="https://hubspot-integrators-prod.s3.amazonaws.com/listing/542129.png" alt="app" />
                    </div>
                    <div className='title-app'>
                        <span>Shopify Online</span>
                    </div>
                </div>
              </div>
              <div className='item-center' >
                <DropdownMenuList img={selectOption} type="button-hs-secondary">
                  <div className="item" /* onClick={() => handleEditDescription(version)} */>
                    <img src={Bidirectional} alt="edit" />
                    <p>Data sync between apps</p>
                  </div>
                  <div className="item" /* onClick={() => handleDeleteDescription(version)} */>
                    <img src={Right} alt="newAppoitment" />
                    <p>Data syncs only to HubSpot</p>
                  </div>
                  <div className="item" /* onClick={() => handleDeleteDescription(version)} */>
                    <img src={Left} alt="newAppoitment" />
                    <p>Data syncs only to QuickBooks Online</p>
                  </div>
                </DropdownMenuList>
              </div>
              <div className='item-right'>
              <div>
                    <div className='image-app'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em" viewBox="0 0 512 512"><path fill="#f97a5a" d="M267.4 211.6c-25.1 23.7-40.8 57.3-40.8 94.6c0 29.3 9.7 56.3 26 78L203.1 434c-4.4-1.6-9.1-2.5-14-2.5c-10.8 0-20.9 4.2-28.5 11.8s-11.8 17.8-11.8 28.6s4.2 20.9 11.8 28.5s17.8 11.6 28.5 11.6c10.8 0 20.9-3.9 28.6-11.6c7.6-7.6 11.8-17.8 11.8-28.5c0-4.2-.6-8.2-1.9-12.1l50-50.2c22 16.9 49.4 26.9 79.3 26.9c71.9 0 130-58.3 130-130.2c0-65.2-47.7-119.2-110.2-128.7V116c17.5-7.4 28.2-23.8 28.2-42.9c0-26.1-20.9-47.9-47-47.9S311.2 47 311.2 73.1c0 19.1 10.7 35.5 28.2 42.9v61.2c-15.2 2.1-29.6 6.7-42.7 13.6c-27.6-20.9-117.5-85.7-168.9-124.8c1.2-4.4 2-9 2-13.8C129.8 23.4 106.3 0 77.4 0C48.6 0 25.2 23.4 25.2 52.2c0 28.9 23.4 52.3 52.2 52.3c9.8 0 18.9-2.9 26.8-7.6zm89.5 163.6c-38.1 0-69-30.9-69-69s30.9-69 69-69s69 30.9 69 69s-30.9 69-69 69"/></svg>
                    </div>
                    <div className='title-app'>
                        <span>HubSpot Contact</span>
                    </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div className="mapping">
        <h4>Mapped fields</h4>
        <p>Field mappings connect individual fields with matching information from each app. You can turn off the default mappings we’ve created for you, or create new custom mappings that are unique to your business. <a href="https://knowledge.hubspot.com/integrations/understand-your-data-sync-field-mappings">Learn more</a> </p>
        <MappingTable />
        <section className='custom-mapping'>
          {!customMapping && <Button className={"hubspot"} bgColor={"#425b76"} color="white" onPress={() => showCustomMapping(true)}>Custom Mappings</Button>}
          {customMapping && <div className='section-custom'>
            <div className='section-custom-inner'>
              <div className='select'>
              <ReactSelect
                options={[
                  {
                    label: 'Balance',
                    value: 10,
                  },
                  {
                    label: 'Currency',
                    value: 25,
                  },
                  {
                    label: 'Company',
                    value: 50,
                  },
                ]}
                theme={(theme) => styleThemeHS(theme)}
                defaultValue={{
                  label: 'Company',
                  value: 25,
                }}
                /* onChange={selectPerPage} */
                menuPlacement="top"
              />
              </div>
              <div className='select'>
                <ReactSelect
                  options={[
                    {
                      label: 'Balance',
                      value: 10,
                    },
                    {
                      label: 'Currency',
                      value: 25,
                    },
                    {
                      label: 'Company',
                      value: 50,
                    },
                  ]}
                  theme={(theme) => styleThemeHS(theme)}
                  defaultValue={{
                    label: 'Company',
                    value: 25,
                  }}
                  /* onChange={selectPerPage} */
                  menuPlacement="top"
                />
              </div>
              <div className='actions'>
                <Button className={"hubspot button--sm"} bgColor={"#425b76"} color="white" bgColorHover={"#425b76"} onPress={() => showCustomMapping(false)}>
                    <div style={{display: "flex", alignItems: "center"}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="1.8em" height="1.8em" viewBox="0 0 36 36"><path fill="white" d="M13.72 27.69L3.29 17.27a1 1 0 0 1 1.41-1.41l9 9L31.29 7.29A1 1 0 0 1 32.7 8.7Z" className="clr-i-outline clr-i-outline-path-1"/><path fill="none" d="M0 0h36v36H0z"/></svg>
                    </div>
                </Button>
                <Button className={"hubspot button--sm"} onPress={() => showCustomMapping(false)}>
                    <div style={{display: "flex", alignItems: "center"}} >
                        <span><svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 16 16"><path fill="currentColor" d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5"/></svg></span>
                    </div>
                </Button>
              </div>
            </div>
          </div>}
        </section>
      </div>
    </ConfigureStyles>
  )
}

export default ConfigureMapping;