import React, { useEffect, useState } from 'react';

import { StyleTable } from './styles';
import Dots from '../../Assets/img/Actions/Dots.svg';
import Sync from '../../Assets/img/Actions/Sync.svg';
import { DropdownMenuList } from '../Dropdown/menuList';
import { PaginationMain } from '../Pagination';
import { AlertConfirm, AlertConfirmHTML, AlertForm, AlertLoading, ICON } from '../../Utils/Alert';
import { useCreateWorkflowMutation, useDeleteWorkflowMutation, useSyncWorkflowMutation, useUpdateCoreWorkflowMutation, useUpdateWorkflowMutation } from '../../Store/api/workflows';
import { Modal } from '../Modals';
import { useModal } from '../../Hooks/useModal';
import { Form } from '../Form';
import { InputForm } from '../Input';
import { Button } from '../Buttons';
import Select from 'react-select';
import { styleTheme } from '../../Styles/SelectTheme';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import { formatDate } from '../../Utils/times';
import { useGetExecutionsByIdWorkflowQuery } from '../../Store/api/executions';
import { Badge } from '../Badge';
import { useSelector } from 'react-redux';
import { useGetCountByIdQuery } from '../../Store/api/count';
import { TableExecutions } from './executions';


export const Table = ({ dataWorkflow, isLoading, filterWorkflowFetch, optionsEstado }) => {
  const { isSuperAdmin, idUser } = useSelector((state) => state.login);
  
  const [queryExecutionsWorkflow, setQueryExecutionsWorkflow] = useState({workflowId: "", query: ""})
  const [selectFilterValue, setSelectFilterValue] = useState({ value: "", label: 'Todos' })
  const [intervalTime, setIntervalTime] = useState(0);
  const [idWorkflowUpdate, setIdWorkflowUpdate] = useState(null);

  const [isOpenModalEdit, openModalEdit, closeModalEdit] = useModal(false);
  const [isOpenModalExecutions, openModalExecutions, closeModalExecutions] = useModal(false);
  let [deleteWorkflowFetch, { isLoading: isLoadingDelete, isSuccess: isSuccessDelete }] = useDeleteWorkflowMutation();
  let [
    updateWorkflowFetch,
    { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, isError: isErrorUpdate, error: errorUpdate },
  ] = useUpdateWorkflowMutation();
  let [
    updateWorkflowCoreFetch,
    { isLoading: isLoadingCoreUpdate, isSuccess: isSuccessCoreUpdate, isError: isErrorCoreUpdate, error: errorCoreUpdate },
  ] = useUpdateCoreWorkflowMutation();
  let [syncWorkflowFetch, { isLoading: isLoadingSyncWF, isSuccess: isSuccessSync, isError: isErrorSync, error: errorSync }] = useSyncWorkflowMutation();
  let [createWorkflowFetch, { isLoading: isLoadingCreate, isSuccess: isSuccessCreate, data: dataCreate }] = useCreateWorkflowMutation();
  let { data: getWorkflowExecutions, isLoading: isLoadingWorkflowExecutions } = useGetExecutionsByIdWorkflowQuery(queryExecutionsWorkflow, {
    pollingInterval: intervalTime,
    refetchOnMountOrArgChange: true,
    skip: false,});
  
  let { data: getCountById } = useGetCountByIdQuery(queryExecutionsWorkflow.workflowId); 
  const [paginationWorkFlow, setPaginationWorkFlow] = useState([]);
  const [errorDataFormEdit, setErrorDataFormEdit] = useState({});
  const [dataFormEdit, setDataFormEdit] = useState({
    name: '',
  });
  const [selectStatus, setSelectStatus] = useState(null);
  const [dataExecutions, setDataExecutions] = useState({
    name: "",
    idWorkflow: ""
  });
  useEffect(() => {
    if (isSuccessDelete) {
      AlertForm({ title: 'Workflow eliminado correctamente...', icon: ICON.SUCCESS });
      filterWorkflowFetch({ term: '' });
    } else if (isLoadingDelete) {
      AlertLoading({ title: 'Eliminando...' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessDelete, isLoadingDelete]);
  useEffect(() => {
    if (isSuccessUpdate) {
      AlertForm({ title: 'Workflow actualizado correctamente...', icon: ICON.SUCCESS });
      filterWorkflowFetch({ term: '' });
      closeModalEdit();
    } else if (isErrorUpdate) {
      AlertForm({
        title: 'Hubo un inconveniente al actualizar',
        text: errorUpdate.data?.message || '',
        icon: ICON.WARNING,
      });
    } else if (isLoadingUpdate) {
      AlertLoading({ title: 'Actualizando...' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUpdate, isLoadingUpdate, isErrorUpdate]);

  useEffect(() => {
    if (isSuccessCoreUpdate) {
      AlertForm({ title: 'Workflow actualizado correctamente...', icon: ICON.SUCCESS });
      filterWorkflowFetch({ term: '' });
    } else if (isErrorCoreUpdate) {
      AlertForm({
        title: 'Hubo un inconveniente al actualizar',
        text: errorCoreUpdate.data?.message || '',
        icon: ICON.WARNING,
      });
    } else if (isLoadingCoreUpdate) {
      AlertLoading({ title: 'Actualizando...' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessCoreUpdate, isLoadingCoreUpdate, isErrorCoreUpdate]);

  useEffect(() => {
    if (isSuccessCreate) {
      AlertLoading({ title: 'Asociando Template...' });
      updateWorkflowCoreFetch({
        id: idWorkflowUpdate,
        body: {
          idParent: dataCreate.data.idCore,
          versionParent: dataCreate.data.version || null,
        },
      });
      filterWorkflowFetch({ term: '' });
    } else if (isLoadingCreate) {
      AlertLoading({ title: 'Creando Plantilla...' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCreate, isSuccessCreate, dataCreate]);
  useEffect(() => {
    if (isSuccessSync) {
      AlertForm({ title: 'Workflow sincronizado y actualizado correctamente...', icon: ICON.SUCCESS });
      filterWorkflowFetch({ term: '' });
    } else if (isErrorSync) {
      AlertForm({
        title: 'Hubo un inconveniente al actualizar',
        text: errorSync.data?.message || '',
        icon: ICON.WARNING,
      });
    } else if (isLoadingSyncWF) {
      AlertLoading({ title: 'Sincronizando...' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessSync, isLoadingSyncWF, isErrorSync]);

  
  useEffect(() => {
      setIntervalTime(isOpenModalExecutions ? 10000: 0)
  }, [isOpenModalExecutions])
  
  const handleOpenWorkflow = (id, restrintion) => {

    if(restrintion){
      //AlertForm({title: "Restricción para abrir!", text: "El workflow esta asociado a un template y no es posible abrir el editor", icon: ICON.INFO})
    } 
    let urlN8N = `${process.env.REACT_APP_N8N_API_URL}/workflow/${id.idWorkflow}`;

    const win = window.open(urlN8N, '_blank');
    win.focus();
    
    
  };
  const handleOpenExecution = (id) => {
    let urlN8N = `${process.env.REACT_APP_N8N_API_URL}/workflow/${dataExecutions.idWorkflow}/executions/${id}`;

    const win = window.open(urlN8N, '_blank');
    win.focus();
  };
  const handleDeleteWorkflow = async ({ id, name }) => {
    let result = await AlertConfirm({ title: 'Esta seguro de Eliminar workflow?', text: `Workflow Nombre: ${name}` });
    if (result.isConfirmed) {
      deleteWorkflowFetch(id);
    }
  };
  const handleExecutions = async ({idWorkflow, name}) => {
    openModalExecutions();
    setQueryExecutionsWorkflow({workflowId: idWorkflow, query:""})
    setDataExecutions({
      name,
      idWorkflow
    })
    setSelectFilterValue({
      value: "",
      label: "Todos"
    })
    document.getElementById("executions-workflow").scrollIntoView();
  };

  const handleUpdateWorkflow = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let validateName = dataFormEdit.name.length >= 3;

    setErrorDataFormEdit({
      name: !validateName ? 'Nombre debe tener 3 caracteres' : '',
    });
    if (validateName) {
      updateWorkflowFetch({
        id: dataFormEdit.id,
        body: {
          name: dataFormEdit.name,
          status: selectStatus.value,
        },
      });
    }
  };

  const changeDataWorkflow = (e) => {
    setDataFormEdit({ ...dataFormEdit, [e.target.name]: e.target.value });
  };

  const handleOpeneditModal = (workflow) => {
    openModalEdit();
    setDataFormEdit({ name: workflow.name, id: workflow.idWorkflow });
    setSelectStatus(optionsEstado.find((el) => el.value === workflow.active));
  };

  const selectStatusFilter = (e) => {
    setSelectStatus(e);
  };

  const selectFilterExecution = (e) => {
    setSelectFilterValue({
      value: e.value,
      label: e.label
    })
    setQueryExecutionsWorkflow({
      workflowId: dataExecutions.idWorkflow,
      query: e.value ? `status=${e.value}` : ""
    })
  }

  const handleDisasociateTemplate = async (workflow) => {
    let result = await AlertConfirm({title: "Desasociar Plantilla!", text: "Seguro de desasociar plantilla?", icon: ICON.WARNING})
    if(result.isConfirmed) {
      updateWorkflowCoreFetch({
        id: workflow.id,
        body: {
          idParent: null
        },
      });
    }
  }

  const handleCreateTemplate = async (workflow) => {
    let result = await AlertConfirm({title: "Crear Plantilla!", text: "Se crea una plantilla, con los nodos y conexiones de este workflow y se asocia a esta Plantilla", icon: ICON.INFO})
    if(result.isConfirmed) {
      try {
        let settings = JSON.parse(JSON.stringify(workflow.settings));
        delete settings.callerPolicy;
        settings.errorWorkflow = Number(settings.errorWorkflow);
        setIdWorkflowUpdate(workflow.id)
        createWorkflowFetch({
          workflow: {
            nodes: workflow.nodes,
            connections: workflow.connections,
            settings,
            staticData: workflow.staticData,
          },
          name: `TEMPLATE - ${workflow.name}`,
          idClient: workflow.idClient,
          author: idUser,
          type: "template"
        });
      } catch (err) {
        console.warn(err);
      }
    }
  }
  const handleSyncWorkflow = async (workflow) => {
    let findDescription = workflow.extension_workflow.versionDescription.find(el => el.versionId === workflow.extension_workflow.versionId);
    let textDescription = `<h3>${findDescription?.versionName || "En desarrollo"}</h3><br> <b>Descripción:</b> ${findDescription?.description || "En desarrollo"}`;

    let result = await AlertConfirmHTML({
      title: "Sincronizar Worflow!", 
      html: textDescription + "<br> <br>Se actualizara el workflow con la ultima versión de la plantilla asociada. Desea Continuar?",
      textButton: "Actualizar",
      icon: ICON.INFO})
    if(result.isConfirmed){
      syncWorkflowFetch({
        id: workflow.id,
        idWorkflow: String(workflow.idWorkflow),
        idParent: workflow.extension_workflow.idWorkflow,
        name: workflow.name,
        tokens: workflow.tokens,
        credentials: workflow.credentials
      })
    }
  }
  return (
    <>
      <StyleTable cols="1.5fr 1fr 1fr 120px 100px">
        <div className="header-table">
          <div>Nombre</div>
          {isSuperAdmin && <div>Plantilla</div>}
          <div>Cliente</div>
          <div>Estado</div>
          <div></div>
        </div>
        <div className="body-table">
          {paginationWorkFlow.map((workflow) => {
            return (
              <div className="row-table" key={`workflow-${workflow.id}`}>
                <div className="col-table">
                  <div>
                    <div className="title">{workflow.name}</div>
                    <div className="details">
                      <div className="detail-text">
                        Última actualización{' '}
                        {formatDistanceToNow(new Date(workflow.updatedAt), {
                          locale: es,
                          addSuffix: true,
                        })}
                      </div>
                      <div className="detail-text">Creado el {formatDate(workflow.createdAt)}</div>
                    </div>
                  </div>
                </div>
                {isSuperAdmin && <div className="col-table">{workflow.idParent ? <div className='action-btn'>
                  <Badge close={true} 
                    onPress={() => handleOpenWorkflow(workflow.extension_workflow, false)}
                    onPressClose={() =>handleDisasociateTemplate(workflow)} bgColor={"gray"} fontSize={"0.8rem"}>{workflow.extension_workflow?.name}
                  </Badge>
                  {workflow.versionParent !== workflow.extension_workflow.versionId && <Button minWidth="50px" className="sync" onPress={() => handleSyncWorkflow(workflow)}><img src={Sync} alt="Sincronizar"/></Button>} </div> : 
                  <Button onPress={() =>handleCreateTemplate(workflow)} fontSize={"0.8rem"}>Crear Plantilla</Button>}
                </div>}
                <div className="col-table">
                  {workflow.client.firstname} {workflow.client.lastname}
                </div>
                <div className="col-table">
                  <div className="section-status">
                    <div className={`circle ${workflow.active ? 'active' : 'inactive'}`}></div>{' '}
                    {workflow.active ? 'Activo' : 'Inactivo'}
                  </div>
                </div>
                {isSuperAdmin ? <div className="col-table">
                  <DropdownMenuList img={Dots}>
                    <div className="item" onClick={() => handleOpeneditModal(workflow)}>
                      <img src={Dots} alt="edit" />
                      <p>Editar</p>
                    </div>
                    <div className="item" onClick={() => handleOpenWorkflow(workflow, workflow.idParent)}>
                      <img src={Dots} alt="edit" />
                      <p>Abrir</p>
                    </div>

                    <div className="item" onClick={() => handleDeleteWorkflow(workflow)}>
                      <img src={Dots} alt="newAppoitment" />
                      <p>Eliminar</p>
                    </div>
                    <div className="item" onClick={() => handleExecutions(workflow)}>
                      <img src={Dots} alt="executions" />
                      <p>Ejecuciones</p>
                    </div>
                  </DropdownMenuList>
                </div>:  <div className="col-table"></div>}
                
              </div>
            );
          })}
          {isLoading && paginationWorkFlow.length === 0 &&
             <div className="not-results">
              <h3>Cargando Resultados!</h3>
            </div>
          }
          {!isLoading && paginationWorkFlow.length === 0 && (
            <div className="not-results">
              <h3>No Hay resultados!</h3>
            </div>
          )}
        </div>
      </StyleTable>
      <PaginationMain data={dataWorkflow} setDataPagination={setPaginationWorkFlow} numberItems={10} />

      <Modal title="Editar Workflow" isOpen={isOpenModalEdit} closeModal={closeModalEdit} closeConfirm={true}>
        <h2>Formulario</h2>
        <div className="form-container">
          <Form onSubmit={handleUpdateWorkflow}>
            <div className="row">
              <div className="form-control col-6">
                <label htmlFor="">Nombre *</label>
                <InputForm
                  placeholder={'Nombre'}
                  type="text"
                  name={'name'}
                  onChange={changeDataWorkflow}
                  value={dataFormEdit.name}
                  error={errorDataFormEdit.name ? true : false}
                />
                <p className={`msg-error ${errorDataFormEdit.name ? 'active' : ''}`}>{errorDataFormEdit.name}</p>
              </div>
              <div className="form-control col-6">
                <label htmlFor="">Estado *</label>
                <div className="container-select">
                  <Select
                    options={optionsEstado}
                    placeholder="Estado Workflow"
                    theme={(theme) => styleTheme(theme)}
                    value={selectStatus}
                    onChange={selectStatusFilter}
                  />
                </div>
              </div>
            </div>

            <div className="text-center">
              <Button type="submit">Editar</Button>
            </div>
          </Form>
        </div>
      </Modal>

      <Modal title="Ejecuciones" isOpen={isOpenModalExecutions} closeModal={closeModalExecutions} closeConfirm={false}>
        {/* <h2 id="executions-workflow">Ejecuciones workflow ID: {dataExecutions.idWorkflow} - {dataExecutions.name}</h2>
        <div className="form-container" >
          {isLoadingWorkflowExecutions && "Cargando...."}
          {!isLoadingWorkflowExecutions && 
            <>
            <h3>Cantidad de Ejecuciones: {getWorkflowExecutions?.data.length}</h3>
            <h3>Cantidad de Transacciones: {getCountById?.data.reduce((a,b) => a + b.count, 0)}</h3>

            <div style={{width: "300px"}}>
              <Select
                options={[
                  { value: "", label: 'Todos' },
                  { value: "success", label: 'Success' },
                  { value: "error", label: 'Failed' }
                ]}
                placeholder="Buscar por Filtro"
                value={selectFilterValue}
                theme={(theme) => styleTheme(theme)}
                onChange={selectFilterExecution}
              />
            </div>

            
            <StyleTable cols="1.5fr 1fr 1fr 1fr 200px">
              <div className="header-table">
                <div>ID / Inicio</div>
                <div>Modo</div>
                <div>Estado</div>
                <div>Tiempo</div>
                <div></div>
              </div>
              <div className="body-table">
              {getWorkflowExecutions?.data.map(execution => {
               return (
                <div className="row-table" key={`execution-${execution.id}`}>
                  <div className="col-table">
                    <div>
                      <div className="title">{execution.id}</div>
                      <div className="details">
                        <div className="detail-text">
                          {formatDate(execution.startedAt)}
                        </div>                        
                      </div>
                    </div>
                  </div>
                  <div className="col-table">
                    <div>
                    {execution.mode.toUpperCase()}
                    <div className="details">
                       { 
                        execution.retryOf && <div className="detail-text">
                          Retry of {execution.retryOf}
                        </div>
                        }
                       { 
                        execution.retrySuccessId && <div className="detail-text">
                          Success Retry {execution.retrySuccessId}
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="col-table">{execution.finished ? <p className='success'>Success</p>  : <p className='failed'>Failed</p>}</div>
                  <div className="col-table">{diferenceTimeInSecond(execution.startedAt, execution.stoppedAt)} sg</div>
                  <div className="col-table"><Button onPress={() => handleOpenExecution(execution.id)}>Ir</Button></div>
                </div>
              )})}
              </div>
            </StyleTable>
            </>
          }
        </div> */}
        <TableExecutions dataExecutions={dataExecutions} intervalTime={intervalTime} queryExecutionsWorkflow={queryExecutionsWorkflow} setQueryExecutionsWorkflow={setQueryExecutionsWorkflow}/>

      </Modal>
    </>
  );
};
