import React, { Fragment, useEffect, useRef, useState } from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Container, PageWrapper } from '../Styles/globalStyles';
import { StylesPages, StylesPageUser } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { autoLoginUser } from '../Store/slices/login/thunks';
import { Login } from '../Views/Login';
import { Home } from '../Views/Home';
import { Header } from '../Components/Header';
import { Menu } from '../Components/Menu';
import { StylesMenu } from '../Components/Menu/styles';
import { NotFound } from '../Views/NotFound';
import { Workflow } from '../Views/Workflows';
import { Clients } from '../Views/Clients';
import { Templates } from '../Views/Templates';
import { Reports } from '../Views/Reports';
import { Mapping } from '../Views/Apps/HubSpot/Mapping';
import ConfigMapping from '../Views/Apps/HubSpot/Mapping/config';
import { Transactions } from '../Views/Apps/HubSpot/Transactions';

const AuthenticatedUser = ({ children }) => {
  const showSidebar = useRef(null);
  const refPage = useRef(null);

  const handleSidebar = () => {
    showSidebar.current.classList.toggle('active-menu');
    refPage.current.classList.toggle('active-menu');
  };
  return (
    <Fragment>
      {/*  <Header onPress={handleSidebar} /> */}
      <StylesPageUser>
        <StylesMenu ref={showSidebar}>
          <Menu onPress={handleSidebar} />
        </StylesMenu>

        <PageWrapper ref={refPage}>
          <Container>{children}</Container>
        </PageWrapper>
      </StylesPageUser>
    </Fragment>
  );
};

const NotAuthenticatedUser = ({ children }) => {
  return (
    <Fragment>
      <StylesPages>
        <PageWrapper>{children}</PageWrapper>
      </StylesPages>
    </Fragment>
  );
};

export const NavigationApp = () => {
  const dispatch = useDispatch();
  const { userLogin } = useSelector((state) => state.login);

  useEffect(() => {
    if ('caches' in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    dispatch(autoLoginUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Router>
        {/* Rutas Publicas que puede acceder cualquier usuario */}
        {!userLogin && (
          <NotAuthenticatedUser>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/apps/mapping" element={<Mapping />} />
              <Route path="/apps/mapping/:object" element={<ConfigMapping />} />
              <Route path="/apps/transactions" element={<Transactions />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </NotAuthenticatedUser>
        )}
        {/* Rutas si hay un login y usuario esta autenticado */}
        {userLogin && (
          <AuthenticatedUser>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/workflows" element={<Workflow />} />
              <Route path="/templates" element={<Templates />} />
              <Route path="/clients" element={<Clients />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </AuthenticatedUser>
        )}
      </Router>
    </Fragment>
  );
};
