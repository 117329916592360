import styled, { keyframes } from 'styled-components';
import { device } from '../../Constants/device';

export const StylesReports= styled.section`
  & .action-workflow {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1.2rem;
    & .form-container {
      width: 530px;
    }
  }

  & .section-search {
    display: flex;
    align-items: center;
    & > button {
      margin-left: 1rem;
      padding: 1rem;
      min-width: auto;
    }
  }
`;
