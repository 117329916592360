import styled from 'styled-components';
import { device } from '../../Constants/device';

export const StylesHeader = styled.header`
  background: #fff;
  padding: 10px 20px;
  box-shadow: 0px -8px 25px #6d6d6d;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
`;
