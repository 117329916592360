import React, { useEffect, useState } from 'react';

import { StylesReports } from './styles';
import { AlertConfirm, AlertForm, AlertLoading, ICON } from '../../Utils/Alert';
import { Button } from '../../Components/Buttons';
import { Input } from '../../Components/Input/InputSecondary';
import { TableClients } from '../../Components/Table/clients';
import { useModal } from '../../Hooks/useModal';
import { Modal } from '../../Components/Modals';

import { InputForm } from '../../Components/Input';
import { Form } from '../../Components/Form';
import { EmailValidate } from '../../Utils/ValidateFields';
import Lupa from '../../Assets/img/Actions/Lupa.svg';
import { TableExecutions } from '../../Components/Table/executions';
import Select from 'react-select';
import { styleTheme } from '../../Styles/SelectTheme';
import { useSelector } from 'react-redux';
import { useFilterWorkflowMutation } from '../../Store/api/workflows';
import { useGetCountTransactionsByIdsQuery } from '../../Store/api/count';

let allIds = "";
export const Reports = () => {
  const { isSuperAdmin, clients, idUser } = useSelector((state) => state.login);
  const [optionsWorkflow, setOptionsWorkflow] = useState([]);
  const [selectFilterExecution, setSelectFilterExecution] = useState({value: null, label: null});
  const [dataWorkflow, setDataWorkflow] = useState([]);
  const [workflowsIds, setWorkflowsIds] = useState("");
  const [dateRange, setDateRange] = useState({start: "", end: ""}); //YYYY-MM-DD
  
  const [totalTransactions, setTotalTransactions] = useState(0);
  let [filterWorkflowFetch, { isLoading: isLoadingFilter, isSuccess: isSuccessFilter, data: getDataFilter }] =
  useFilterWorkflowMutation();
  const [queryExecutionsWorkflow, setQueryExecutionsWorkflow] = useState({workflowId: "", query: ""})
  let { data: getTransactionsByIds, isLoading: isLoadingTransactions, isSuccess: isSuccessTransactions } = useGetCountTransactionsByIdsQuery(workflowsIds); 

  const [errorDataForm, setErrorDataForm] = useState({
    end: '',
    start: ''
  });

  useEffect(() => {
    if(isSuperAdmin) {
      filterWorkflowFetch({ term: '' });
    } else {
      filterWorkflowFetch({ term: '', user: [2, idUser], clients: clients });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterWorkflowFetch]);

  useEffect(() => {
    if (isSuccessFilter) {
      let arrayIds = [];
      for (const data of getDataFilter.data) {
        arrayIds.push(data.idWorkflow);
      }
      allIds = arrayIds.join(",");
      setWorkflowsIds(arrayIds.join(","));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessFilter]);

  useEffect(() => {
    if (isSuccessTransactions && getDataFilter) {
      let newData = [];
      let options = [];
      for (const data of getDataFilter.data) {
        let findData = getTransactionsByIds.data.find(el => data.idWorkflow === el.idWorkflow);
        newData.push({
          name: data.name,
          count: findData?.count || 0
        })
        options.push({
          value: data.idWorkflow, label: data.name
        })
      }
      setTotalTransactions(getTransactionsByIds.data.find(el => el.idWorkflow === "all")?.count || 0);
      setDataWorkflow(newData);
      setOptionsWorkflow(options)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessTransactions, getTransactionsByIds]);

  const selectFilterViewExecution = (e) => {
    setSelectFilterExecution({
      value: e.value,
      label: e.label
    })
    setQueryExecutionsWorkflow({
      workflowId: e.value,
      query: ""
    })
  }

  const changeDateRange = (e) => {
    let { name, value } = e.target;

    if(name === 'start') {
      setDateRange({start: value, end: dateRange.end});
    } else if (name === 'end') {
      setDateRange({start: dateRange.start, end: value});
    }
  }

  const handleSearchDateRange = (e) => {
    e.preventDefault();
    let queryDate = "";
    if(dateRange.start) queryDate += `dateStart=${dateRange.start}`;
    if(dateRange.end) queryDate +=  `${queryDate ? "&": ""}dateEnd=${dateRange.end}`;
    setWorkflowsIds(`${allIds}${queryDate ? `?${queryDate}` : ""}`);
    setQueryExecutionsWorkflow({
      workflowId: selectFilterExecution.value,
      query: queryDate ? `${queryDate}` : ""
    })
  }

  return (
    <StylesReports>
      <h2 className="title-main">Reportes</h2>

      <section className="action-workflow">
      <div className="form-container">
        <Form onSubmit={handleSearchDateRange}>
          <div className="row">
            <div className="form-control col-6">
              <label htmlFor="">Fecha Inicio *</label>
              <InputForm
                type="date"
                name={'start'}
                onChange={changeDateRange}
                value={dateRange.start}
                error={errorDataForm.start ? true : false}
              />
              <p className={`msg-error ${errorDataForm.start ? 'active' : ''}`}>{errorDataForm.start}</p>
            </div>
            <div className="form-control col-6">
              <label htmlFor="">Fecha Fin *</label>
              <InputForm
                type="date"
                name={'end'}
                onChange={changeDateRange}
                value={dateRange.end}
                error={errorDataForm.end ? true : false}
                max={new Date().toJSON().split("T")[0]}
              />
              <p className={`msg-error ${errorDataForm.end ? 'active' : ''}`}>{errorDataForm.end}</p>
            </div>
          </div>

          <div className="text-center">
            <Button type="submit" /* disabled={isLoadingCreate || isLoadingUpdate} */>
              {/*  {isLoadingCreate || isLoadingUpdate ? 'Enviando...' : isCreateForm ? 'Crear' : 'Editar'} */}
              Buscar
            </Button>
          </div>
        </Form>
      </div>
      
      </section>
      <section>
        <hr />
        <p>Según las fechas consultadas, tienes a la fecha un consumo de {totalTransactions} transacciones:</p> 

        <p>Transacciones por flujos</p>
        <ul>
          {dataWorkflow.map(workflow => {
             return (<li><strong>{workflow.name}:</strong> {workflow.count || 0}</li>)
          }
          )}
        </ul>
          <hr />
        <div style={{width: "300px"}}>
              <label htmlFor="">Buscar Workflow</label>
                <Select
                    options={optionsWorkflow}
                    placeholder="Buscar por Workflow"
                    value={selectFilterExecution}
                    theme={(theme) => styleTheme(theme)}
                    onChange={selectFilterViewExecution}
                />
        </div>
      <hr />
      <p style={{marginTop: "1rem"}}>Aqui puedes encontrar el detalle de cada una de las transacciones </p>


        <TableExecutions
          dataExecutions={{ 
            name: selectFilterExecution.label,
            idWorkflow: selectFilterExecution.value
          }}
          intervalTime={60000}
          queryExecutionsWorkflow={queryExecutionsWorkflow} 
          setQueryExecutionsWorkflow={setQueryExecutionsWorkflow}
        />
      </section>
    </StylesReports>
  );
};
