import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useGetCountByIdQuery } from '../../Store/api/count';
import { useGetExecutionsByIdWorkflowQuery } from '../../Store/api/executions';
import { styleTheme } from '../../Styles/SelectTheme';
import { diferenceTimeInSecond, formatDate } from '../../Utils/times'
import { Button } from '../Buttons';
import { StyleTable } from './styles';

export const TableExecutions = ({dataExecutions, intervalTime, queryExecutionsWorkflow, setQueryExecutionsWorkflow}) => {
    const { isSuperAdmin } = useSelector((state) => state.login);
    const [selectFilterValue, setSelectFilterValue] = useState({ value: '', label: 'Todos' });

    let { data: getWorkflowExecutions, isLoading: isLoadingWorkflowExecutions } = useGetExecutionsByIdWorkflowQuery(queryExecutionsWorkflow, {
        pollingInterval: intervalTime,
        refetchOnMountOrArgChange: true,
        skip: false,});

    let { data: getCountById } = useGetCountByIdQuery(queryExecutionsWorkflow.workflowId + "?" + queryExecutionsWorkflow.query); 

    const handleOpenExecution = (id) => {
        let urlN8N = `${process.env.REACT_APP_N8N_API_URL}/workflow/${dataExecutions.idWorkflow}/executions/${id}`;
        const win = window.open(urlN8N, '_blank');
        win.focus();
    };
    const selectFilterExecution = (e) => {
        setSelectFilterValue({
            value: e.value,
            label: e.label
        })
        setQueryExecutionsWorkflow({
            workflowId: dataExecutions.idWorkflow,
            query: e.value ? `status=${e.value}` : ""
        });
    }

    /* const handleExecutions = async ({idWorkflow, name}) => {
        setQueryExecutionsWorkflow({workflowId: idWorkflow, query:""})
        
        setSelectFilterValue({
          value: "",
          label: "Todos"
        })
        document.getElementById("executions-workflow").scrollIntoView();
      }; */
    return (
        <>
            <h2 id="executions-workflow">Ejecuciones workflow ID: {dataExecutions.idWorkflow} - {dataExecutions.name}</h2>
            <div className="form-container" >
            {isLoadingWorkflowExecutions && "Cargando...."}
            {!isLoadingWorkflowExecutions && 
                <>
                <h3>Cantidad de Ejecuciones: {getWorkflowExecutions?.data.length}</h3>
                <h3>Cantidad de Transacciones: {getCountById?.data.reduce((a,b) => a + b.count, 0)}</h3>

                <div style={{width: "300px"}}>
                <Select
                    options={[
                    { value: "", label: 'Todos' },
                    { value: "success", label: 'Success' },
                    { value: "error", label: 'Failed' }
                    ]}
                    placeholder="Buscar por Filtro"
                    value={selectFilterValue}
                    theme={(theme) => styleTheme(theme)}
                    onChange={selectFilterExecution}
                />
                </div>

                
                <StyleTable cols="1.5fr 1fr 1fr 1fr 200px">
                <div className="header-table">
                    <div>ID / Inicio</div>
                    <div>Modo</div>
                    <div>Estado</div>
                    <div>Tiempo</div>
                    <div></div>
                </div>
                <div className="body-table">
                {getWorkflowExecutions?.data.map(execution => {
                return (
                    <div className="row-table" key={`execution-${execution.id}`}>
                    <div className="col-table">
                        <div>
                        <div className="title">{execution.id}</div>
                        <div className="details">
                            <div className="detail-text">
                            {formatDate(execution.startedAt)}
                            </div>                        
                        </div>
                        </div>
                    </div>
                    <div className="col-table">
                        <div>
                        {execution.mode.toUpperCase()}
                        <div className="details">
                        { 
                            execution.retryOf && <div className="detail-text">
                            Retry of {execution.retryOf}
                            </div>
                            }
                        { 
                            execution.retrySuccessId && <div className="detail-text">
                            Success Retry {execution.retrySuccessId}
                            </div>
                            }
                        </div>
                        </div>
                    </div>
                    <div className="col-table">{execution.finished ? <p className='success'>Success</p>  : <p className='failed'>Failed</p>}</div>
                    <div className="col-table">{diferenceTimeInSecond(execution.startedAt, execution.stoppedAt)} sg</div>
                    {isSuperAdmin &&<div className="col-table"><Button onPress={() => handleOpenExecution(execution.id)}>Ir</Button></div>}
                    </div>
                )})}
                </div>
                </StyleTable>
                </>
            }
            </div>
        </>
    )
}

