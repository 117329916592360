import styled, { keyframes } from 'styled-components';

export const StylesProgress = styled.section`
  
  display: flex;
  align-tiems: center;
  gap: 2rem;
  & .circle-transactions {
    width: 250px;
    font-size: 3rem;
  }
  & .info-transactions {
    text-align: center;
  }
`
 